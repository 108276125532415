import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

export default function ConfirmUnblockedOrganizationModal({ open, setOpen, changeBlockedOrganizationStatus }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{padding: "10px 10px 20px"}}>
        <DialogContent sx={{ padding: "35px" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "var(--mainText)", fontSize: "16px", fontWeight: "500" }}
          >
            Вы уверены что хотите продолжить операцию?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "var(--red)" }}>
            Отмена
          </Button>
          <Button
            className="btn btn-primary-fill "
            variant="outlined"
            onClick={() => changeBlockedOrganizationStatus()}
            autoFocus
          >
            Продолжить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
