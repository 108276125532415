import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const logsApi = createApi({
  reducerPath: "logs/api",
  tagTypes: ['Logs'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getLogOfWorkDoneOnTasks: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/logs/get-logs-list?${stringParams}`,
        };
      },
      providesTags: ['Logs']
    }),
    getSystemLogList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/logs/get-system-log-list?${stringParams}`,
        };
      },
      providesTags: ['Logs']
    }),
    getListEventMessages: build.query({
      query: ({ FloorInfoId }) => ({
        url: `api/logs/get-last-event-messages?takeCount=100${FloorInfoId}`,
      }),
      providesTags: ['Logs']
    }),
    getActionList: build.query({
      query: () => ({
        url: `api/logs/get-action-list`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name }) => ({
          value: id,
          name,
        })),
      providesTags: ['Logs']
    }),
    getEntityList: build.query({
      query: (name) => ({
        url: `api/logs/get-entity-list?name=${name}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, visibleName }) => ({
          value:id,
          name:visibleName,
        })),
      providesTags: ['Logs']
    }),
    getEntityFieldList: build.query({
      query: ({entityTypeId,name}) => ({
        url: `api/logs/get-entity-field-list?name=${name}&entityTypeId=${entityTypeId}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, visibleName }) => ({
          value:id,
          name:visibleName,
        })),
      providesTags: ['Logs']
    }),
    getListEventMessagesWithRooms: build.query({
      query: () => ({
        url: `api/logs/get-last-event-messages-with-rooms?takeCount=100`,
      }),
      providesTags: ['Logs']
    }),
    printLogsList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/logs/print-logs-list?${stringParams}`,
        };
      },
      providesTags: ['Logs']
    }),
    printEventLogsList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/logs/print-event-logs-list?${stringParams}`,
        };
      },
      providesTags: ['Logs']
    }),
  }),
});

export const {
  useLazyGetLogOfWorkDoneOnTasksQuery,
  useGetActionListQuery,
  useLazyGetEntityFieldListQuery,
  useGetEntityListQuery,
  useLazyGetSystemLogListQuery,
  useLazyGetListEventMessagesQuery,
  useLazyGetListEventMessagesWithRoomsQuery,
  useLazyPrintLogsListQuery,
  useLazyPrintEventLogsListQuery
} = logsApi;
