import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from "../../../constants";
import plusicon from "../../../image/icons/plusBtnWhite.png";
import {
  useLazyGetNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from "../../../middleware/notificationApi";
import { NotificationItem } from "../../components/NotificationPopUp/NotificationItem";
import CloseIcon from "@mui/icons-material/Close";
const Header = ({ handleModalDebbuging }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const anchorRef = React.useRef(null);
  const [notifications, setNotifications] = useState({});
  const { roleId } = useAppSelector((state) => state.users.info);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageNumber, changePageNumber] = useState(0);

  const [getNotification, { error: errorNotif, refetch: refetchNotifications /* isLoading */ }] =
    useLazyGetNotificationsQuery();
  const [markNotificationAsRead, { error }] = useMarkNotificationAsReadMutation();

  const fetchData = async () => {
    try {
      const response = await getNotification({
        pageNumber: 0,
        excludeSurgardNotifications: false
      });
      setNotifications({
        ...response?.data,
        hasMore: response?.data?.data.length < response?.data.recordsCount ? true : false,
      });
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    open && fetchData()
  }, [open])

  const markNotification = (array) => {
    markNotificationAsRead(array).then(({ data, error }) => {
      if (!error) {
        fetchData();
      }
    });
  };

  const notiffetchData = () => {
    setLoading(true);
    setTimeout(() => {
      getNotification({
        pageNumber: pageNumber + 1,
      }).then(({ data, isSuccess }) => {
        changePageNumber((prev) => prev + 1);
        setNotifications({
          ...notifications,
          hasMore: data?.data.length + notifications?.data.length < data.recordsCount ? true : false,
          data: [...notifications?.data, ...data.data],
        });
        setLoading(false);
      });
    }, 1000);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom >= 1 && bottom <= 10 && !loading && notifications?.hasMore) {
      notiffetchData();
    }
  };

  return (
    <Box className="controllers-header">
      <Typography className="title-page">{t("title_page:controllers")}</Typography>
      <Box className="controllers-header-btns_field">
        <Button
          ref={anchorRef}
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{ height: "52px" }}
        >
          {t("Диагностика")}
        </Button>
        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={handleModalDebbuging.bind(null, true)}
          sx={{ height: "52px" }}
        >
          {t("button:debugging")}
        </Button>
        {(roleId == 1 || roleId == 2) && (
          <Button
            className="btn btn-primary-fill"
            variant="outlined"
            onClick={() => navigate(PATHS.ADD_CONTROLLER)}
            sx={{ height: "52px" }}
          >
            <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
            {t("button:add")}
          </Button>
        )}
      </Box>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal sx={{ zIndex: 10000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="paper-wrapper">
              <Box sx={{ position: "absolute", right: "40px", top: "40px" }}>
                <IconButton onClick={() => setOpen(false)} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <div className="notification-wrapper">
                <div className="notification-title-wrapper">
                  <p className="notification-title">{t("label:notifications")}</p>
                </div>

                {notifications?.recordsCount > 0 ? (
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="text"
                      onClick={() => markNotification(notifications.data.map((notification) => notification?.id))}
                    >
                      <Typography className="mark-laber "></Typography>
                    </Button>
                  </Box>
                ) : (
                  ""
                )}

                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  {notifications?.recordsCount > 0 ? (
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      onScroll={handleScroll}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        height: "50vh",
                        overflow: "hidden",
                        overflowY: "auto",
                        paddingBottom: "0px",
                      }}
                    >
                      {notifications.data.map((item) => {
                        return (
                          <NotificationItem key={item?.id} markNotification={markNotification} notification={item} />
                        );
                      })}

                      {loading && (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    </MenuList>
                  ) : (
                    <Box
                      sx={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography> {t("label:no_notifications")}</Typography>
                    </Box>
                  )}
                </ClickAwayListener>
              </div>
              <div className="notification-footer">
                <button
                  onClick={() => markNotification(notifications.data.map((notification) => notification?.id))}
                  className="info"
                >
                  {t("label:mark_all_as_read")}
                </button>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default Header;
