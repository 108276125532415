import React from "react";
import { Placemark } from "@pbe/react-yandex-maps";
import { useNavigate } from "react-router-dom";
import { useAction } from "../../../hooks/actions";

import "./styles/style.css";
import { PATHS, globalFields } from "../../../constants";

const CustomPlacemark = ({ id, geometry, onClick, name, jobChart, linkChart, onClickPopUp, onTaskStateClick }) => {
  const navigate = useNavigate();
  const { setSortParamsFacilityName } = useAction();
  const getPath = (name) => globalFields[name];

  window.handleNavigate = (props, id) => {
    onTaskStateClick(id, props);
  };

  window.goToObject = (id) => {
    onClickPopUp(id);
  };

  return (
    <Placemark
      key={id}
      geometry={geometry}
      onClick={onClick}
      options={{
        iconLayout: "default#image",
        iconImageHref: linkChart,
        iconImageSize: [45, 45],
      }}
      properties={{
        balloonContentHeader: `<strong style="display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical; font-family: var(--INTER)">${name}</strong>`,
        balloonContentBody: `
          <div class="balloon_content">
            <ul class="balloon_content-list">
              <li class="balloon_content-list-item">
                <p onclick="window.handleNavigate('overdued_jobs_amount', '${id}')" class="link-black">
                  <span class="link_point" style="background-color: #fb0104;"></span>
                  Просроченные: ${jobChart?.overduedJobsAmount || 0}
                </p>
              </li>
              <li class="balloon_content-list-item">
                <p onclick="window.handleNavigate('near_deadline_jobs_amount', '${id}')" class="link-black">
                  <span class="link_point" style="background-color: #ff6800;"></span>
                  Подходит срок: ${jobChart?.nearDeadlineJobsAmount || 0}
                </p>
              </li>
              <li class="balloon_content-list-item">
                <p onclick="window.handleNavigate('not_near_deadline_jobs_amount', '${id}')" class="link-black">
                  <span class="link_point" style="background-color: #fee902;"></span>
                  Текущие: ${jobChart?.notNearDeadlineJobsAmount || 0}
                </p>
              </li>
              <li class="balloon_content-list-item">
                <p onclick="window.handleNavigate('completed_intime_jobs_amount', '${id}')" class="link-black">
                  <span class="link_point" style="background-color: #316f46;"></span>
                  Выполнено в срок: ${jobChart?.completed_intime_jobs_amount || 0}
                </p>
              </li>
            </ul>
          </div>
        `,
        balloonContentFooter: `
          <div class="balloon_footer">
            <p
              class="balloon_footer-text"
              onclick="window.goToObject('${id}')"
            > 
              Подробнее...
            </p>          
          </div>
        `,
        clusterCaption: `<strong style="display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical; font-family: var(--INTER)">${name}</strong>`,
      }}
      modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
      defaultState={{ balloon: { open: true } }}
      instanceRef={(ref) => ref && ref.balloon && ref.balloon.close()}
    />
  );
};

export default CustomPlacemark;
