import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Box,
  Chip,
  InputAdornment,
  ListSubheader,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import checkImagechecked from "../../../image/icons/checkboxChecked.png"
import checkImage from "../../../image/icons/checkBox.png"
import searchIcon from "../../../image/icons/searchIcon.png"
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 30;
const SEARCH_HEIGHT= 72;

const SubheaderSearch = ({ searchValue, setSearchValue }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Escape") {
      e.stopPropagation();
    }
  };

  return (
    <ListSubheader
      sx={{
        zIndex: 5,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <TextField
        fullWidth
        autoFocus
        placeholder={t("placeholders:search_select")}
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img src={searchIcon} />
            </InputAdornment>
          ),
        }}
      />
    </ListSubheader>
  );
};

export const SelectMultipleChipDeletableWithSearchField = ({
  options,
  label,
  value,
  onChange,
  error,
  displayEmpty = false,
  placeholder,
  disabled = false,
  searchValue,
  setSearchValue,
  firstData = [],
  isForEdit,
  isIds = false,
}) => {
  const { t } = useTranslation();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: options?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 + SEARCH_HEIGHT : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * options?.length + SEARCH_HEIGHT ,
      },
    },
  };

  const [selectedValues, setSelectedValues] = useState([]);

  const [ranOnce, setranOnce] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleChange = (event) => {

    const {
      target: { value },
    } = event;
    let targetValue = value
    if (isIds) {
      targetValue = value?.map(({ id }) => id) || [];
    }
    setSelectedValues(value);
    onChange(targetValue);
  };

  const handleDelete = (e, value) => {
    e.preventDefault();

    let requestData = selectedValues?.filter((ee) => ee?.id !== value?.id);
    if (isIds) {
      requestData = requestData?.map(({ id }) => id) || [];
    }
    setSelectedValues(requestData);
    onChange(requestData);
  };
  useEffect(() => {
    if (!value?.length) return;

    if (firstData) {
      console.log("SelectMultipleChipDeletableWithSearchField", firstData, value)

      setSelectedValues(firstData.filter(o => value.includes(o?.id)))

    }
  }, [firstData])

  useEffect(() => {

    if (!value?.length) return;
    if (isIds) {
      if (firstData) {
        setSelectedValues(firstData.filter(o => value.includes(o?.id)))
      }
    } else {
      setSelectedValues(value)
    }

  }, [value])



  const checkChecked = (selected, currentId) => {
    let isSelect = false
    selected.forEach(obj => {
      if (obj.id === currentId) {
        isSelect = true
      }
    });
    return isSelect
  }

  return (
    <Box sx={{ position: "relative" }} className="input_text">
      <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      <FormControl fullWidth error={!!error}>
        <Select
          multiple
          value={selectedValues}
          disabled={disabled}
          displayEmpty={displayEmpty}
          onChange={handleChange}
          input={<OutlinedInput />}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() =>
            <Box sx={{ mr: "24px", cursor: "pointer" }} onClick={() => { setIsOpen((prev) => !prev) }}>
              {isOpen ? (
                <img src={selectArrowUp} />
              ) : (
                <img src={selectArrowDown} />
              )
              }
            </Box>}
          renderValue={(selected) => (
            selected?.length > 0 ? (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>

                {selected?.map((value, i) =>
                  !disabled ? (
                    <Chip
                      key={i}
                      label={value?.name}
                      clickable
                      deleteIcon={
                        <ClearIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={(e) => handleDelete(e, value)}
                    />
                  ) : (
                    <Chip
                      key={i}
                      label={value?.name || value?.standard}
                      clickable
                    />
                  )
                )}
              </Box>
            )
              : (t(placeholder) || "Нет")
          )}
          MenuProps={MenuProps}
        >
          <SubheaderSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          {options?.filter(({ name }) => new RegExp(searchValue, "gi").test(name)).map((option, i) => (
            <MenuItem
              key={i}
              value={option}
              sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
            >
              <Checkbox checked={checkChecked(selectedValues, option?.id)}
                sx={{ height: "28px", width: "28px", mr: "10px", borderRadius: 0 }}
                icon={<Box
                  component="img"
                  src={checkImage}
                />}
                checkedIcon={<Box
                  component="img"
                  src={checkImagechecked}
                />}
              />
              <ListItemText primary={option?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
