import { t } from "i18next";
import { TYPE_ID, fields } from "./constants";

export const typeOptions = [
  // { value: t("tableHeader:organizationType"), name: t("options_name:everything") },
  {
    value: TYPE_ID[fields.organization_customer],
    name: t(`options_name:${fields.organization_customer}`),
  },
  {
    value: TYPE_ID[fields.organization_contractor],
    name: t(`options_name:${fields.organization_contractor}`),
  },
  {
    value: TYPE_ID[fields.outside_organization],
    name: t(`options_name:${fields.outside_organization}`),
  },
];
