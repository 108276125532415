import React from "react";
import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form";

import { formFields } from "../form-config";

import FormItem from "../../../components/FormItem";
import InputBlockField from "../../../components/InputBlockField";
import FormItemCheckboxControlled from "../../../components/FormItem/FormItemCheckboxControlled";
import { buildingCharacteristicsFormConfigCheckbox } from "./form-config";

export const BuildingPassportFrom = ({ config, section, readonly }) => {
  const { control, errors, register, unregister, setValue, getValues } =
    useFormContext();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "24px",
          width: "50%",
        }}
      >
        {config.map(
          ({ id, name, sectionName, label, input, expended, ...rest }) =>
            expended ? (
              <FormItemCheckboxControlled
                key={name}
                control={control}
                errors={errors}
                register={register}
                unregister={unregister}
                setValue={setValue}
                getValues={getValues}
                name={name}
                disabled={readonly}
                label={label}
                input={input}
                expended={expended}
                sectionName={sectionName || section}
                {...rest}
              />
            ) : (
              <FormItem
                key={
                  sectionName || section
                    ? `${sectionName || section}.${name}`
                    : name
                }
                control={control}
                errors={errors}
                disabled={readonly}
                name={
                  rest?.type === "file"
                    ? `${[formFields.files]}.${name}`
                    : sectionName || section
                      ? `${sectionName || section}.${name}`
                      : name
                }
                label={label}
                input={input}
                expended={expended}
                {...rest}
              />
            )
        )}
      </Box>
    </Box>
  );
};

export const FacilitySystemsForm = ({ config, section, readonly }) => {
  const { control, errors, register, unregister, setValue, getValues } =
    useFormContext();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "24px",
          width: "50%",
        }}
      >
        {config.map(
          ({
            id,
            name,
            sectionName,
            label,
            input,
            block,
            expended,
            title,
            ...rest
          }) =>
            block ? (
              <InputBlockField
                key={name}
                name={name}
                label={label}
                input={input}
                disabled={readonly}
                expended={expended}
                title={title}
                control={control}
                errors={errors}
                register={register}
                unregister={unregister}
                sectionName={sectionName || section}
                setValue={setValue}
                getValues={getValues}
                formFields={formFields}
                {...rest}
              />
            ) : expended ? (
              <FormItemCheckboxControlled
                key={name}
                control={control}
                errors={errors}
                disabled={readonly}
                register={register}
                unregister={unregister}
                setValue={setValue}
                getValues={getValues}
                name={name}
                label={label}
                input={input}
                expended={expended}
                sectionName={sectionName || section}
                {...rest}
              />
            ) : (
              <FormItem
                key={
                  sectionName || section
                    ? `${sectionName || section}.${name}`
                    : name
                }
                control={control}
                errors={errors}
                disabled={readonly}
                name={
                  rest?.type === "file"
                    ? `${[formFields.files]}.${name}`
                    : sectionName || section
                      ? `${sectionName || section}.${name}`
                      : name
                }
                label={label}
                input={input}
                expended={expended}
                {...rest}
              />
            )
        )}
      </Box>
    </Box>
  );
};

export const BuildingCharacteristicsFrom = ({ config, section, readonly }) => {
  const { control, errors, register, unregister, setValue, getValues } =
    useFormContext();

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 300px)",
          alignItems: "end",
          justifyContent: "flex-start",
          gap: "24px",
          width: "100%",

          "& .MuiInputLabel-root": {
            whiteSpace: "wrap",
            overflow: "normal",
            textOverflow: "-moz-initial",
          },
        }}
      >
        {config.map(({ name, sectionName, label, input, expended, ...rest }) =>
          expended ? (
            <FormItemCheckboxControlled
              key={name || new Date()}
              control={control}
              errors={errors}
              register={register}
              disabled={readonly}
              unregister={unregister}
              setValue={setValue}
              getValues={getValues}
              name={name}
              label={label}
              input={input}
              expended={expended}
              sectionName={sectionName || section}
              {...rest}
            />
          ) : (
            <FormItem
              key={name || new Date()}
              control={control}
              errors={errors}
              disabled={readonly}
              name={`${sectionName || section}.${name}`}
              label={label}
              input={input}
              {...rest}
            />
          )
        )}
      </Box>
      <Box sx={{ml: '10px', display: 'flex', flexDirection: 'column', gap: '24px', mt: '24px'}}>
        {buildingCharacteristicsFormConfigCheckbox.map(({ name, sectionName, label, input, expended, ...rest }) =>
          expended ? (
            <FormItemCheckboxControlled
              key={name || new Date()}
              control={control}
              errors={errors}
              register={register}
              disabled={readonly}
              unregister={unregister}
              setValue={setValue}
              getValues={getValues}
              name={name}
              label={label}
              input={input}
              expended={expended}
              sectionName={sectionName || section}
              {...rest}
            />
          ) : (
            <FormItem
              key={name || new Date()}
              control={control}
              errors={errors}
              disabled={readonly}
              name={`${sectionName || section}.${name}`}
              label={label}
              input={input}
              {...rest}
            />
          )
        )}
      </Box>
    </Box>
  );
};
