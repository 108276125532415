import SelectSimple from "../../components/SelectSimple";
import React from "react";
import InputText from "../../components/InputText";
import {
  AFASOptions,
  AFSSTOptions,
  fireAndExplosionHazard,
  fireResistanceDegreeOptions,
  functionFireSecurityClasses,
  potentialRadiationHazardOptions,
  SNCEOptions,
  structuralFireHazardClassOptions,
  typesAlarmOptions,
  typeWiringOptions,
  ventilationTypeOptions,
  voltageOptions,
} from "../ObjectsList/options";
import FloorFields from "./FloorFields";
import { FloorShortFields } from "./FloorShortFields";
import CheckboxSimple from "../../components/CheckboxSimple";
import { Box, InputAdornment, Typography } from "@mui/material";
import { t } from "i18next";
import DatePickerField from "../../components/DatePickerField";
import { useAppSelector } from "../../../hooks/redux";
import { FireCompartmentFields } from './FireCompartmentFields/FireCompartmentFileds';

export const formFields = {
  protectionObjectId: "protectionObjectId",
  name: "name",
  compartmentNumber: "compartmentNumber",
  fireDangerClasses: "fireDangerClasses",
  functionalPurpose: "functionalPurpose",
  height: "height",
  width: "width",
  length: "length",
  square: "square",
  radDangerCategory: "radDangerCategory",
  fireResistCategory: "fireResistCategory",
  constructFireDangerCategory: "constructFireDangerCategory",
  outdoorInstallFireDangerCategory: "outdoorInstallFireDangerCategory",
  fireExitsAmount: "fireExitsAmount",
  peopleInsideBuildingAmount: "peopleInsideBuildingAmount",
  hasFireElevators: "hasFireElevators",
  staircaseAmount: "staircaseAmount",
  hasGroundFloor: "hasGroundFloor",
  hasUndergroundFloor: "hasUndergroundFloor",
  hasInternalFWSupply: "hasInternalFWSupply",
  hasFWSupplyContract: "hasFWSupplyContract",
  fireNoozlesAmount: "fireNoozlesAmount",
  hasVent: "hasVent",
  ventType: "ventType",
  autoHeatFuelType: "autoHeatFuelType",
  fireCompartmentSystems: "fireCompartmentSystems",

  floorAmount: "floorAmount",
  floorNumber: "floorNumber",
  floors: "floors",
  comment: "comment",
  floorSchema: "floorSchema",
  undergroundFloorAmount: "undergroundFloorAmount",

  compartmentCount: "compartmentCount",

  externalFireWaterSupplyAvailability: "externalFireWaterSupplyAvailability",
  specializedOrganizationProtectionInvolvement:
    "specializedOrganizationProtectionInvolvement",
  fireHydrantsCount: "fireHydrantsCount",

  notifAndEvacControlSysAvailable: "notifAndEvacControlSysAvailable",
  notifAndEvacControlSysType: "notifAndEvacControlSysType",
  notifAndEvacControlSysMaintenanceContract:
    "notifAndEvacControlSysMaintenanceContract",
  notifAndEvacControlSysInstallationDate:
    "notifAndEvacControlSysInstallationDate",
  notifAndEvacControlSysOverhaulDate: "notifAndEvacControlSysOverhaulDate",
  autoFireAlarmSysAvailable: "autoFireAlarmSysAvailable",
  autoFireAlarmSysType: "autoFireAlarmSysType",
  duplicatingToTheRemoteControl: "duplicatingToTheRemoteControl",
  autoFireAlarmSysMaintenanceContract: "autoFireAlarmSysMaintenanceContract",
  autoFireAlarmSysInstallationDate: "autoFireAlarmSysInstallationDate",
  autoFireAlarmSysOverhaulDate: "autoFireAlarmSysOverhaulDate",
  detectorsType: "detectorsType",
  autoFireSuppressionSysAvailable: "autoFireSuppressionSysAvailable",
  autoFireSuppressionSysType: "autoFireSuppressionSysType",
  autoFireSuppressionSysMaintenanceContract:
    "autoFireSuppressionSysMaintenanceContract",
  autoFireSuppressionSysInstallationDate:
    "autoFireSuppressionSysInstallationDate",
  autoFireSuppressionSysOverhaulDate: "autoFireSuppressionSysOverhaulDate",
  electricityAvailable: "electricityAvailable",
  voltage: "voltage",
  wiringType: "wiringType",
  electricityInstallationDate: "electricityInstallationDate",
  electricityOverhaulDate: "electricityOverhaulDate",
  gasAvailable: "gasAvailable",
  gasInstallationDate: "gasInstallationDate",
  gasOverhaulDate: "gasOverhaulDate",
  fullTimeStayAvailability: "fullTimeStayAvailability",
  newYearMassAvailabilityOfChildren: "newYearMassAvailabilityOfChildren",
  storesAndSellsPyrotechnicProducts: "storesAndSellsPyrotechnicProducts",
  involvedInElections: "involvedInElections",
  particularlyHazardousAndTechnicallyComplex:
    "particularlyHazardousAndTechnicallyComplex",
  criticalToCountriesNationalSecurity: "criticalToCountriesNationalSecurity",
  hazardousProductionFacilitiesAvailability:
    "hazardousProductionFacilitiesAvailability",
  bookRepositoryOrArchiveOfFederalImportance:
    "bookRepositoryOrArchiveOfFederalImportance",
  fireCompartmentsCount: "fireCompartmentsCount",
};

const FacFireCompSelect = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={facilitySelectElements} {...props} />;
};
export const newFireCompartmentShortSection = [
  {
    id: 1,
    items: [
      {
        name: formFields.fireCompartmentsCount,
        label: "Количество пожарных отсеков",
        type: "text",
        rules: {
          required: true,
        },
        input: (props) => <InputText {...props} />,
      },
      {
        id: 2,
        rules: {
          required: true,
        },
        flexBasis: "49%",
        CustomFormItem: FireCompartmentFields,
      },
    ],
  },
];

export const fireCompartmentShortSections = [
  {
    id: 1,
    // label: "label:compartment",
    items: [
      {
        name: formFields.name,
        label: "label:fireCompartmentName",
        type: "text",
        rules: {
          required: true,
        },
        input: (props) => <InputText {...props} />,
      },
      {
        id: 2,
        rules: {
          required: true,
        },
        flexBasis: "49%",
        CustomFormItem: FloorShortFields,
      },
    ],
  },
];

export const fireCompartmentSections = [
  {
    id: 1,
    label: "label:object",
    items: [
      {
        name: formFields.protectionObjectId,
        label: "label:object",
        type: "select-text",
        rules: {
          required: true,
        },
        input: (props) => <FacFireCompSelect {...props} />,
      },
    ],
  },
  {
    id: 2,
    label: "label:fireCompartmentPassport",
    items: [
      {
        name: formFields.name,
        label: "label:fireCompartmentName",
        type: "text",
        rules: {
          required: true,
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.compartmentNumber,
        label: "label:fireCompartmentNumber",
        type: "text",
        rules: {
          required: true,
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.fireDangerClasses,
        label: "label:fireDangerClasses",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: functionFireSecurityClasses,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.functionalPurpose,
        label: "label:mainFunctionalPurpose",
        type: "text",
        rules: {},
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
    ],
  },
  {
    id: 3,
    label: "label:fireCompartmentCharacteristic",
    items: [
      {
        id: 4,
        rules: {
          required: true,
        },
        flexBasis: "49%",
        CustomFormItem: FloorFields,
      },
      {
        name: formFields.height,
        label: "label:height",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">м</Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.width,
        label: "label:width",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">м</Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.length,
        label: "label:length",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">м</Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.square,
        label: "label:square",
        type: "number",
        rules: {
          fractional: (v) => !!Number(v) && !/\s/g.test(v),
        },
        flexBasis: "49%",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className="adornment">
                м<sup>2</sup>
              </Typography>
            </InputAdornment>
          ),
        },
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.radDangerCategory,
        label: "label:potentialRadiationHazardCategory",
        type: "select-number",
        rules: {},
        flexBasis: "49%",
        options: potentialRadiationHazardOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.fireResistCategory,
        label: "label:fireResistanceDegree",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: fireResistanceDegreeOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.constructFireDangerCategory,
        label: "label:structuralFireHazardClass",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: structuralFireHazardClassOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.outdoorInstallFireDangerCategory,
        label: "label:fireAndExplosionHazardOutdoorInstCategory",
        type: "select-text",
        rules: {},
        flexBasis: "49%",
        options: fireAndExplosionHazard,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.fireExitsAmount,
        label: "label:evacuationExitsCount",
        type: "number",
        rules: {
          min: 1,
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.peopleInsideBuildingAmount,
        label: "label:simultaneousPeopleCount",
        type: "number",
        rules: {
          min: 1,
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.hasFireElevators,
        label: "label:elevatorToTransportFirefightersAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.staircaseAmount,
        label: "label:stairwellsCount",
        type: "number",
        rules: {
          min: 1,
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.hasGroundFloor,
        label: "label:basementFloorAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.hasInternalFWSupply,
        label: "label:internalFireWaterSupplyAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.hasUndergroundFloor,
        label: "label:groundFloorAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fireNoozlesAmount,
        label: "label:fireHosesCount",
        type: "number",
        rules: {
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        flexBasis: "49%",
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.hasFWSupplyContract,
        label: "label:fireWaterSupplyMaintenanceContractAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "100%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.hasVent,
        label: "label:ventilationAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        expended: [
          {
            name: formFields.ventType,
            label: "label:ventilationType",
            type: "select-text",
            rules: {},
            flexBasis: "49%",
            options: ventilationTypeOptions,
            input: (props) => <SelectSimple {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.autoHeatFuelType,
        label: "label:fuelForStandaloneHeatingType",
        type: "text",
        flexBasis: "100%",
        input: (props) => (
          <Box>
            <Typography className="input-block-title">
              {t("title:heating")}
            </Typography>
            <InputText {...props} />
          </Box>
        ),
      },
      {
        name: formFields.externalFireWaterSupplyAvailability,
        label: "label:externalFireWaterSupplyAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.specializedOrganizationProtectionInvolvement,
        label: "label:specializedOrganizationProtectionInvolvement",
        type: "checkbox",
        rules: {},
        flexBasis: "49%",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fireHydrantsCount,
        label: "label:fireHydrantsCount",
        type: "number",
        rules: {
          pattern: /^([0-9]+)$/,
          integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
        },
        input: (props) => (
          <Box sx={{ maxWidth: "300px" }}>
            <InputText {...props} />
          </Box>
        ),
      },
    ],
  },
  {
    id: 5,
    label: "label:facilitySystems",
    name: formFields.fireCompartmentSystems,
    items: [
      {
        name: formFields.notifAndEvacControlSysAvailable,
        label: "label:notifAndEvacControlSysAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:SNME",
        expended: [
          {
            name: formFields.notifAndEvacControlSysType,
            label: "label:notifAndEvacControlSysType",
            type: "select-number",
            rules: {},
            flexBasis: "49%",
            options: SNCEOptions,
            input: (props) => <SelectSimple {...props} />,
          },
          {
            name: formFields.notifAndEvacControlSysMaintenanceContract,
            label: "label:notifAndEvacControlSysMaintenanceContract",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.notifAndEvacControlSysInstallationDate,
            label: "label:notifAndEvacControlSysInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.notifAndEvacControlSysOverhaulDate,
            label: "label:notifAndEvacControlSysOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.autoFireAlarmSysAvailable,
        label: "label:autoFireAlarmSysAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:AFA",
        expended: [
          {
            name: formFields.autoFireAlarmSysType,
            label: "label:autoFireAlarmSysType",
            type: "select-text",
            rules: {},
            options: AFASOptions,
            input: (props) => <SelectSimple {...props} />,
          },
          {
            name: formFields.duplicatingToTheRemoteControl,
            label: "label:duplicatingToTheRemoteControl",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.autoFireAlarmSysMaintenanceContract,
            label: "label:autoFireAlarmSysMaintenanceContract",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.autoFireAlarmSysInstallationDate,
            label: "label:autoFireAlarmSysInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.autoFireAlarmSysOverhaulDate,
            label: "label:autoFireAlarmSysOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.detectorsType,
            label: "label:detectorsType",
            type: "select-text",
            rules: {},
            flexBasis: "49%",
            options: typesAlarmOptions,
            input: (props) => <SelectSimple {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.autoFireSuppressionSysAvailable,
        label: "label:autoFireSuppressionSysAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:AFSS",
        expended: [
          {
            name: formFields.autoFireSuppressionSysType,
            label: "label:autoFireSuppressionSysType",
            type: "select-text",
            rules: {},
            flexBasis: "49%",
            options: AFSSTOptions,
            input: (props) => <SelectSimple {...props} />,
          },
          {
            name: formFields.autoFireSuppressionSysMaintenanceContract,
            label: "label:autoFireSuppressionSysMaintenanceContract",
            rules: {},
            type: "checkbox",
            flexBasis: "49%",
            input: (props) => <CheckboxSimple {...props} />,
          },
          {
            name: formFields.autoFireSuppressionSysInstallationDate,
            label: "label:autoFireSuppressionSysInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.autoFireSuppressionSysOverhaulDate,
            label: "label:autoFireSuppressionSysOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.electricityAvailable,
        label: "label:electricityAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:powerSupply",
        expended: [
          {
            name: formFields.voltage,
            label: "label:voltage",
            type: "select-number",
            rules: {},
            options: voltageOptions,
            input: (props) => (
              <Box sx={{ width: "49%" }}>
                <SelectSimple {...props} />
              </Box>
            ),
          },
          {
            name: formFields.electricityInstallationDate,
            label: "label:electricityInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.electricityOverhaulDate,
            label: "label:electricityOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.wiringType,
            label: "label:wiringType",
            type: "select-text",
            rules: {},
            options: typeWiringOptions,
            input: (props) => (
              <Box sx={{ width: "49%" }}>
                <SelectSimple {...props} />
              </Box>
            ),
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.gasAvailable,
        label: "label:gasAvailable",
        type: "checkbox",
        rules: {},
        block: true,
        title: "title:gas",
        expended: [
          {
            name: formFields.gasInstallationDate,
            label: "label:gasInstallationDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.gasOverhaulDate,
            label: "label:gasOverhaulDate",
            type: "date",
            rules: {},
            flexBasis: "49%",
            sx: { width: "100%" },
            input: (props) => <DatePickerField {...props} />,
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.fullTimeStayAvailability,
        label: "label:fireCompartment:fullTimeStayAvailability",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.newYearMassAvailabilityOfChildren,
        label: "label:fireCompartment:newYearMassAvailabilityOfChildren",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.storesAndSellsPyrotechnicProducts,
        label: "label:fireCompartment:storesAndSellsPyrotechnicProducts",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.involvedInElections,
        label: "label:fireCompartment:involvedInElections",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.particularlyHazardousAndTechnicallyComplex,
        label:
          "label:fireCompartment:particularlyHazardousAndTechnicallyComplex",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.criticalToCountriesNationalSecurity,
        label: "label:fireCompartment:criticalToCountriesNationalSecurity",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.hazardousProductionFacilitiesAvailability,
        label:
          "label:fireCompartment:hazardousProductionFacilitiesAvailability",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.bookRepositoryOrArchiveOfFederalImportance,
        label:
          "label:fireCompartment:bookRepositoryOrArchiveOfFederalImportance",
        type: "checkbox",
        input: (props) => <CheckboxSimple {...props} />,
      },
    ],
  },
];
