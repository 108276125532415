import React from "react";
import Box from "@mui/material/Box";
import { InputLabel } from "@mui/material";
import { t } from "i18next";

import { fileTypeCheck, fileSizeCheck } from "../../../../validation";
import { formFields } from "../form-config";
import { timezones } from "../options";

import {
  BuildingPassportFrom,
  BuildingCharacteristicsFrom,
  FacilitySystemsForm,
  OrganizationsSelect,
  FacilityOptionsSelect,
} from "./Components";
import InputText from "../../../components/InputText";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";
import { AddressInput } from "../../../components/AddressInput";
import SelectSimple from "../../../components/SelectSimple";
// import RiskCategoryButton from "../../../components/ToggleButtonsGroup/RiskCategoryButton";
// import ToggleButtonsGroup from "../../../components/ToggleButtonsGroup";
import FireCompartments from "../FireCompartments";
// import { YearPickerField } from "../../../components/DatePickerField/YearPickerField";
import CheckboxSimple from "../../../components/CheckboxSimple";
import DatePickerField from "../../../components/DatePickerField";
import { ExecutiveUserNameSelectSimple } from "./Components";
import RadioButtonsGroup from "../../../components/RadioButtons";
// import IntNumbersFields from "../../FireCompartments/IntShortFields/index";
export const formFieldsBasicInf = {
  entity: "entity",
  buildingPassport: "buildingPassport",
  buildingCharacteristics: "buildingCharacteristics",
  facilitySystems: "facilitySystems",
};

export const serviceTypesRadio = [
  {
    name: t("options_name:everymonth"),
    value: 1,
  },
  {
    name: t("options_name:everyquad"),
    value: 2,
  },
];

export const organizationFormConfig = [];

export const buildingPassportFormConfig = [
  {
    id: 12,
    name: formFields.facilityOptionId,
    label: "label:object_option",
    type: "select",
    displayEmpty: true,
    placeholder: "placeholder:facilityOption",
    rules: {
      //required: true,
    },
    input: (props) => <FacilityOptionsSelect {...props} />,
  },
  {
    id: 0,
    name: formFields.organizations,
    label: "label:organizations",
    type: "select-multi",
    displayEmpty: true,
    placeholder: "placeholder:ur_face",
    rules: {
      required: true,
    },
    input: (props) => <OrganizationsSelect {...props} />,
  },
  {
    id: 1,
    name: formFields.facilityName,
    label: "label:facilityName",
    placeholder: "placeholder:name_safe_object",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => {
      const { name, label, ...rest } = props;
      return (
        <Box sx={{ minWidth: "250px" }}>
          <InputLabel sx={{ mb: "6px" }} htmlFor={name}>
            {t(label)}
          </InputLabel>
          <InputText id={name} name={name} {...rest} />
        </Box>
      );
    },
  },
  {
    id: 2,
    name: formFields.buildingFacadePhoto,
    label: "label:buildingFacadePhoto",
    type: "file",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png"];
          return fileTypeCheck(files.filesToAdd, formFields.buildingFacadePhoto, availableFileTypes);
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(files.filesToAdd, formFields.buildingFacadePhoto, maxAvailableFileSize);
        },
      },
    },
    canDeleteFile: true,
    // preview: true,
    input: (props) => (
      <Box sx={{ maxWidth: "100%", mb: "10px" }}>
        <InputFileSingle {...props} accept={".png, .jpg, .jpeg"} />
      </Box>
    ),
  },
  // {
  //   id: 3,
  //   name: formFields.mainFunctionalPurpose,
  //   label: "label:mainFunctionalPurpose",
  //   placeholder: "placeholder:func_safe_object",
  //   type: "text",
  //   rules: {
  //     required: true,
  //   },
  //   flexBasis: "49%",
  //   input: (props) => <InputText {...props} />,
  // },
  {
    id: 4,
    name: formFields.objectAddress,
    label: "label:objectAddress",
    placeholder: "placeholder:object_address",
    rules: {
      required: true,
    },
    shouldSetCoordinates: true,
    input: (props) => <AddressInput {...props} />,
  },
  /* {
    id: 3,
    name: formFields.objectAddress,
    label: "label:objectAddress",
    rules: {
      required: true,
    },
    shouldSetCoordinates: true,
    input: (props) => <YandexMapInput {...props} />,
  },*/
  {
    id: 5,
    name: formFields.gmtTimeZone,
    label: "label:gmtTimeZone",
    type: "select-number",
    options: timezones,
    rules: {
      required: true,
    },
    flexBasis: "49%",
    input: (props) => <SelectSimple {...props} />,
  },
  // {
  //   id: 7,
  //   name: formFields.commissioningDate,
  //   label: "label:commissioningDate",
  //   type: "date",
  //   placeholder: "placeholder:object_year",
  //   rules: {
  //     required: true,
  //   },
  //   flexBasis: "49%",
  //   sx: { width: "100%" },
  //   input: (props) => <YearPickerField {...props} />,
  // },
  // {
  //   id: 8,
  //   name: formFields.hazardClass,
  //   label: "label:hazardClass",
  //   type: "select-text",
  //   rules: {},
  //   flexBasis: "49%",
  //   placeholder: "placeholder:class_fire_dangerous",
  //   displayEmpty: true,
  //   sx: { paddingTop: "18px", width: "100%" },
  //   options: functionFireSecurityClasses,
  //   input: (props) => (
  //     <Box sx={{ display: "flex", "& .input_text": { width: "100%" } }}>
  //       <SelectSimple {...props} />{" "}
  //     </Box>
  //   ),
  // },
];

export const buildingCharacteristicsFormConfig = [
  // {
  //   name: formFields.fireHosesCount,
  //   label: "label:fireHosesCount",
  //   placeholder: "placeholder:count",
  //   type: "number",
  //   rules: {
  //     pattern: /^([0-9]+)$/,
  //     integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
  //   },
  //   input: (props) => (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         gap: "24px",
  //         "& .input_text": { width: "100%" },
  //       }}
  //     >
  //       <InputText name={formFields.fireHosesCount} label="label:fireHosesCount" {...props} />
  //     </Box>
  //   ),
  // },

  // {
  //   id: 11,
  //   name: formFields.protectionObjectIntegrNumbersAlts,
  //   rules: {
  //     pattern: /^([0-9]+)$/,
  //     integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
  //   },
  //   type: "number",
  //   CustomFormItem: (props) => <IntCountOfFireEquipment {...props}/>,
  // },

  // {
  //   id: 11,
  //   name: formFields.fireCompartmentsCount,
  //   label: "label:fireCompartmentsCount",
  //   type: "number",
  //   placeholder: "placeholder:count",
  //   rules: {
  //     required: true,
  //     integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
  //   },
  //   flexBasis: "49%",
  //   input: (props) => (
  //     <Box>
  //       <InputText name={formFields.fireCompartmentsCount} label="label:fireCompartmentsCount" {...props} />
  //     </Box>
  //   ),
  // },

  {
    id: 12,
    name: formFields.fireCompartments,
    CustomFormItem: FireCompartments,
  },
  /* {
    name: formFields.fireHydrantsCount,
    label: "label:fireHydrantsCount",
    type: "number",
    rules: {
      pattern: /^([0-9]+)$/,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    flexBasis: "49%",
    input: (props) => <InputText {...props} />,
  }, */
];

export const facilitySystemsFormConfig = [
  {
    id: 0,
    name: "СОУЭ",
    label: "label:notifAndEvacControlSysAvailable",
    type: "checkbox",
    rules: {},
    expended: [
      // {
      //   name: formFields.notifAndEvacControlSysEquipAmount,
      //   label: "label:total_number_equipment",
      //   placeholder: "placeholder:count",
      //   rules: {
      //     pattern: /^([0-9]+)$/,
      //     integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
      //   },
      //   type: "number",
      //   input: (props) => (
      //     <Box sx={{ maxWidth: "315px", m: "24px 0" }}>
      //       <InputText {...props} />
      //     </Box>
      //   ),
      // },
      {
        name: formFields.notifAndEvacControlSysMaintenanceContract,
        label: "label:notifAndEvacControlSysMaintenanceContract",
        rules: {},
        type: "checkbox",
        sx: { margin: "24px 0 0 24px" },
        //input: (props) => <CheckboxSimple {...props} />,
        expended: [
          {
            name: formFields.notificationAndEvacSysContractNumber,
            label: "label:contractNumber",
            placeholder: "placeholder:object_number",
            type: "text",
            input: (props) => (
              <Box sx={{ maxWidth: "315px", m: "24px 0" }}>
                <InputText {...props} />
              </Box>
            ),
          },
          {
            name: formFields.notifAndEvacSysContractType,
            type: "radio",
            options: serviceTypesRadio,
            input: (props) => <RadioButtonsGroup {...props} />,
          },
          {
            name: formFields.notifAndEvacSysContractStartDate,
            label: "С",
            type: "date",

            sx: {
              minWidth: "200px",
              display: "inline-table",
              width: "20%",
              m: "24px 24px 24px 0",
            },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.notifAndEvacSysContractEndDate,
            label: "по",
            type: "date",
            sx: { minWidth: "200px", display: "inline-table", width: "20%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.notifAndEvacSysContractOrgId,
            label: t("label:executiveUserName"),
            type: "select-text",
            disabled: false,
            displayEmpty: true,
            placeholder: "placeholder:executive_user",
            sx: { minWidth: "200px", width: "40%" },
            input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
          },
          /* {
            name: formFields.snmeContractFile,

            rules: {
             
              validate: {
                type: (files) => {
                  const availableFileTypes = [
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                  ];
                  return fileTypeCheck(
                    files.filesToAdd,
                    formFields.snmeContractFile,
                    availableFileTypes
                  );
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.snmeContractFile,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            uploadBtnText: "button:uploadSingle",
            input: (props) => (
              <Box sx={{ mb: "25px" }}>
                <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
              </Box>
            ),
          },*/
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
    ],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 1,
    name: "СПС",
    label: "label:availabilitySPS",
    type: "checkbox",
    rules: {},
    expended: [
      // {
      //   name: formFields.autoFireAlarmSysEquipAmount,
      //   placeholder: "placeholder:count",
      //   label: "label:total_number_equipment",
      //   rules: {
      //     pattern: /^([0-9]+)$/,
      //     integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
      //   },
      //   type: "number",
      //   input: (props) => (
      //     <Box sx={{ maxWidth: "315px", m: "24px 0" }}>
      //       <InputText {...props} />
      //     </Box>
      //   ),
      // },
      {
        name: formFields.autoFireAlarmSysMaintenanceContract,
        label: "label:autoFireAlarmSysMaintenanceContract",
        type: "checkbox",
        sx: { margin: "24px 0 0 24px" },
        //input: (props) => <CheckboxSimple {...props} />,
        expended: [
          {
            name: formFields.automaticFireAlarmSysContractNumber,
            label: "label:contractNumber",
            placeholder: "placeholder:object_number",
            type: "text",
            input: (props) => (
              <Box sx={{ maxWidth: "315px", m: "24px 0" }}>
                <InputText {...props} />
              </Box>
            ),
          },
          {
            name: formFields.automaticFireAlarmSysContractType,

            type: "radio",

            disabled: false,
            options: serviceTypesRadio,
            input: (props) => <RadioButtonsGroup {...props} />,
          },
          {
            name: formFields.automaticFireAlarmSysContractStartDate,
            label: "С",
            type: "date",
            sx: {
              minWidth: "200px",
              display: "inline-table",
              width: "20%",
              m: "24px 24px 24px 0",
            },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.automaticFireAlarmSysContractEndDate,
            label: "По",
            type: "date",
            sx: { minWidth: "200px", display: "inline-table", width: "20%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.automaticFireAlarmSysContractExecOrgId,
            label: t("label:executiveUserName"),
            displayEmpty: true,
            placeholder: "placeholder:executive_user",
            type: "select-text",
            disabled: false,
            sx: { minWidth: "200px", width: "40%" },
            input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
          },
          /* {
            name: formFields.afaContractFile,

            rules: {
             
              validate: {
                type: (files) => {
                  const availableFileTypes = [
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                  ];
                  return fileTypeCheck(
                    files.filesToAdd,
                    formFields.afaContractFile,
                    availableFileTypes
                  );
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.afaContractFile,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            uploadBtnText: "button:uploadSingle",
            input: (props) => (
              <Box sx={{ mb: "25px" }}>
                <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
              </Box>
            ),
          },*/
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
    ],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 2,
    name: "АСПТ",
    label: "label:autoFireSuppressionSysAvailable",
    type: "checkbox",
    block: true,
    expended: [
      // {
      //   name: formFields.autoFireSuppressionSysEquipAmount,
      //   label: "label:total_number_equipment",
      //   placeholder: "placeholder:count",
      //   rules: {
      //     pattern: /^([0-9]+)$/,
      //     integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
      //   },
      //   type: "number",
      //   input: (props) => (
      //     <Box sx={{ maxWidth: "315px", m: "24px 0" }}>
      //       <InputText {...props} />
      //     </Box>
      //   ),
      // },
      {
        name: formFields.autoFireSuppressionSysMaintenanceContract,
        label: "label:autoFireSuppressionSysMaintenanceContract",
        type: "checkbox",
        //input: (props) => <CheckboxSimple {...props} />,
        sx: { margin: "24px 0 0 24px" },
        expended: [
          {
            name: formFields.automaticFireSupSysContractNumber,
            label: "label:contractNumber",
            placeholder: "placeholder:object_number",
            type: "text",
            input: (props) => (
              <Box sx={{ maxWidth: "315px", m: "24px 0" }}>
                <InputText {...props} />
              </Box>
            ),
          },
          {
            name: formFields.automaticFireSubSysContractType,

            type: "radio",

            disabled: false,
            options: serviceTypesRadio,
            input: (props) => <RadioButtonsGroup {...props} />,
          },
          {
            name: formFields.automaticFireSupSysContractStartDate,
            label: "С",
            type: "date",
            sx: {
              minWidth: "200px",
              display: "inline-table",
              width: "20%",
              m: "24px 24px 24px 0",
            },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.automaticFireSupSysContractEndDate,
            label: "По",
            type: "date",
            sx: { minWidth: "200px", display: "inline-table", width: "20%" },
            input: (props) => <DatePickerField {...props} />,
          },
          {
            name: formFields.automaticFireSupSysContractExecOrgId,
            label: t("label:executiveUserName"),
            type: "select-text",
            displayEmpty: true,
            placeholder: "placeholder:executive_user",
            disabled: false,
            sx: { minWidth: "200px", width: "40%" },
            input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
          },
          /* {
            name: formFields.afasysContractFile,

            rules: {
             
              validate: {
                type: (files) => {
                  const availableFileTypes = [
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                  ];
                  return fileTypeCheck(
                    files.filesToAdd,
                    formFields.afasysContractFile,
                    availableFileTypes
                  );
                },
                size: (files) => {
                  const maxAvailableFileSize = 10; // MBs
                  return fileSizeCheck(
                    files.filesToAdd,
                    formFields.afasysContractFile,
                    maxAvailableFileSize
                  );
                },
              },
            },
            canDeleteFile: false,
            preview: false,
            uploadBtnText: "button:uploadSingle",
            input: (props) => (
              <Box sx={{ mb: "25px" }}>
                <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
              </Box>
            ),
          },*/
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
    ],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 3,
    name: "Внутренний противопожарный водопровод (ВПВ)",
    label: "Внутренний противопожарный водопровод (ВПВ)",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 4,
    name: "Первичные средства пожаротушения (ПСП)",
    label: "Первичные средства пожаротушения (ПСП)",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 5,
    name: "Система противодымной вентиляции (ПВ)",
    label: "Система противодымной вентиляции (ПВ)",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 6,
    name: "Система передачи извещений о пожаре (СПИ)",
    label: "Система передачи извещений о пожаре (СПИ)",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 7,
    name: "Деревянные конструкции с огнезащитой",
    label: "Деревянные конструкции с огнезащитой",
    type: "checkbox",
    tooltip:
      "стропильные конструкции, деревянные конструкции межэтажных перекрытий, деревянные напольные покрытия, деревянные сценические комплексы, нуждающиеся в огнестойкости, выполненные из древесины",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 8,
    name: "Заполнение проемов в противопожарных преградах",
    label: "Заполнение проемов в противопожарных преградах",
    type: "checkbox",
    tooltip: "Противопожарные: Двери, ворота, люки, клапаны, шторы и экраны",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 9,
    name: "Пожарные лестницы и ограждения кровли",
    label: "Пожарные лестницы и ограждения кровли",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 10,
    name: "Водоисточники наружного пожаротушения",
    label: "Водоисточники наружного пожаротушения",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  // {
  //   id: 11,
  //   name: "Дымоход",
  //   label: "Дымоход",
  //   type: "checkbox",
  //   expended: [],
  //   input: (props) => <CheckboxSimple {...props} />,
  // },
  {
    id: 12,
    name: "Средства индивидуальной защиты",
    label: "Средства индивидуальной защиты",
    type: "checkbox",
    expended: [],
    input: (props) => <CheckboxSimple {...props} />,
  },
  // {
  //   id: 13,
  //   name: "Вентиляция производственная",
  //   label: "Вентиляция производственная",
  //   type: "checkbox",
  //   expended: [],
  //   input: (props) => <CheckboxSimple {...props} />,
  // },
];

export const sections = [
  {
    id: 1,
    idd: "baseAcc1",
    title: formFieldsBasicInf.buildingPassport,
    Component: BuildingPassportFrom,
    config: buildingPassportFormConfig,
  },
  {
    id: 2,
    idd: "baseAcc2",
    title: formFieldsBasicInf.buildingCharacteristics,
    Component: BuildingCharacteristicsFrom,
    config: buildingCharacteristicsFormConfig,
  },
  {
    id: 3,
    idd: "baseAcc3",
    title: formFieldsBasicInf.facilitySystems,
    Component: FacilitySystemsForm,
    config: facilitySystemsFormConfig,
  },
];
