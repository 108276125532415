import { ClickableNameCell, IsBlockedOrganizationCell } from "./Components";
import FormItem from "../../components/FormItem";
import { Box } from "@mui/material";
import { formFields } from "./form-config";
import { typeOptions } from "./options";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import { t } from "i18next";
export const getColumns = ({
  control,
  columnsFromStorage,
  orgTypeFilter,
  handleChange,
  searchInn,
  setsearchInn,
  searchName,
  setsearchName,
  searchAddress,
  setsearchAddress,
  isShowBlockedColumn,
  writeSearchDataToLocalStorage,
}) =>
  [
    {
      id: formFields.name,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:name"),
      size: "150px",
      isVisible: columnsFromStorage[formFields.name],
      clickable: true,
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <Box>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.name}`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:name")}
                className="select_in_built small-light"
                searchValue={searchName}
                changeSearchValue={setsearchName}
                writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
                storageValue="searchNameStorage"
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.innNumber,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: true,
      isVisible:  columnsFromStorage[formFields.innNumber],
      HeaderCell: () => (
        <Box>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.innNumber}`}
            sx={{ padding: "9px 0 9px 16px", width: "100%" }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:innNumber")}
                className="select_in_built small-light"
                searchValue={searchInn}
                changeSearchValue={setsearchInn}
                writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
                storageValue="searchInnStorage"
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.typeName,
      numeric: false,
      enableSorting: false,
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.typeName],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.deadlineStatus}`}
          label={t("tableHeader:organizationType")}
          sx={{
            padding: "13px 0 13px 16px",
            width: "100%",
          }}
          options={typeOptions}
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              className="select_in_built small-light"
              value={orgTypeFilter}
              onChange={handleChange}
            />
          )}
        />
      ),
    },

    {
      id: formFields.organizationAddresses,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:address"),
      size: 368,
      isVisible: columnsFromStorage[formFields.organizationAddresses],
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            padding: "0",
          }}
        >
          <AddressInputInbuilt
            placeholder={t("tableHeader:address")}
            className="select_in_built small-light"
            style={{ background: "white" }}
            value={searchAddress}
            onChange={setsearchAddress}
            writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
            storageValue="searchAddressStorage"
          />
        </Box>
      ),
    },
    {
      id: formFields.tasks,
      numeric: false,
      label: t("tableHeader:tasks"),
      size: 80,
      isVisible: columnsFromStorage[formFields.tasks],
      mode: "diagram",
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            borderLeft: "none",
            fontSize: "16px",
            color: "var(--headers)",
            textAlign: "center",
          }}
        >
          {t("tableHeader:tasks")}
        </Box>
      ),
    },
    {
      id: formFields.balance,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:balance"),
      size: "180px",
      isVisible: true,
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            borderLeft: "none",
            textAlign: "center",
            fontSize: "16px",
            color: "var(--headers)",
          }}
        >{t("tableHeader:balance")}</Box>
      ),
    },
    {
      id: formFields.isBlocked,
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: isShowBlockedColumn,
      clickable: false,
      RowCell: (props) => <IsBlockedOrganizationCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            borderLeft: "none",
            textAlign: "center",
            fontSize: "16px",
            color: "var(--headers)",
          }}
        >Состояние организации</Box>
      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      mode: "edit",
      isVisible: true,
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            height: "50px",
            borderLeft: "none",
            borderRadius: "0px 40px 40px 0",
          }}
        ></Box>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
