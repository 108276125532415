
import CheckboxSimple from "../../../components/CheckboxSimple";
export const joinToTotalBill = [
  { status: true, name: "" },
  { status: false, name: "" },
];
export const formFields = {
  index: "index",
  name: "name",
  tarif: "tarif",
  billingDate: "billingDate",
  bill: "bill",
  totalBill:"totalBill",
  faciltyName:"faciltyName",
  searchParams:"searchParams",
  price: "price",
  total: "total"
};


export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));


