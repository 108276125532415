import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "./hooks/redux";
import { Can } from "./casl/Can";
import { AppRoutes, blockedOrganizationRoutes, NonAuthRoutes } from "./views/AppRoutes";
import { useKeyPress } from "./hooks/useFocusOnEnter";
import { parseJsonToken } from "./helpers/helper";
import { useIsOrganizationBlockedMutation } from "./middleware/billingApi";
import { APP_VERSION } from "./middleware/constants";
import AlertNewVersion from "./views/AlertNewVersion";
import { ErrorFallback } from "./views/errorsPages/ErrorFallBack";
import PageNotFound from "./views/errorsPages/PageNotFound";
import Layout from "./views/containers/Layout";
import { PATHS } from "./constants";
import "./styles/globalStyles/globalstyles.css";
import "./styles/variablesStyles/colors/colors.css";
import "./styles/variablesStyles/fonts/fonts.css";
import "./styles/variablesStyles/sizes/sizes.css";
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.users.token);
  const [permissions, setPermissions] = useState([]);
  const { roleId } = useAppSelector((state) => state.users.info);
  const [open, setOpen] = useState(false);
  const [isOrganizationBlockedState, setIsOrganizationBlockedState] = useState();

  const [isOrganizationBlocked, {}] = useIsOrganizationBlockedMutation();

  function checkFacilitiesAvailability(array) {
    return array.includes("facilities");
  }
  useKeyPress();
  const facilitiesAvailable = checkFacilitiesAvailability(permissions);
  useEffect(() => {
    const appVersion = localStorage.getItem("AppVersion");
    if (appVersion) {
      if (appVersion != APP_VERSION) {
        //setOpen(true)
      }
    } else {
      localStorage.setItem("AppVersion", APP_VERSION);
    }
  }, []);

  // Save the current location in the state object
  const saveCurrentLocation = () => {
    navigate(location.pathname, { state: { from: location } });
  };

  // Call saveCurrentLocation when the component mounts
  useEffect(() => {
    saveCurrentLocation();
  }, []);

  useEffect(() => {
    const storedPath = localStorage.getItem("currentPath");
    if (storedPath) {
      localStorage("currentPath"); // Remove the stored path once used
      navigate(storedPath);
    }
  }, [navigate]);
  useEffect(() => {
    token && parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, setPermissions]);

  const protectedRoutes = AppRoutes.filter(
    (item) => permissions.includes(item.permission) || item.permission === "profile"
  );

  const theme = createTheme({
    typography: {
      fontFamily: "var(--INTER)",
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #CFD9E8",
            margin: "0px 20px",
            alignContent: "center",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {},
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "24px", // Устанавливаем желаемый радиус границы
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            width: "100% !important", // Устанавливаем желаемый радиус границы
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          paper: {
            borderRadius: "24px", // Устанавливаем желаемый радиус границы
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            alignContent: "center",
            "& .MuiTypography-root": {
              fontSize: "16px !important",
              lineHeight: "20px !important", // Изменяем размер шрифта внутреннего MuiTypography
            },
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            margin: "0px 12px",
            padding: "12px 0px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            padding: "3.5px 0px 3.5px 15px !important",
          },
        },
      },
      MuiTreeItem2: {
        styleOverrides: {
          // Указываем стили для классов компонента
          groupTransition: {
            padding: 0, // Убираем padding для группы дочерних элементов
            // Если вам нужно изменить этот конкретный класс, добавьте селектор
            "&.MuiCollapse-root": {
              padding: 0, // Убираем padding для класса MuiCollapse-root
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          input: {
            fontSize: "16px", // Изменяем размер шрифта
            "&:active": {
              color: "#051d39",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              paddingRight: "24px !important",
              height: "48px !important",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            color: "#d1d8e0",
            width: "calc(100% - 80px)",
            fontSize: "16px",
            lineHeight: "16px",
            padding: "12.5px 0px 12.5px 24px",
            "&:focus": {
              color: "#051d39",
              "& .MuiSelect-select": {
                alignContent: "center",
                color: "#051d39", // Изменяем размер шрифта внутреннего MuiTypography
              },
            },

            "&:hover": {
              color: "#051d39",
              borderColor: "#c1c1c4",
              outline: "none",
            },
          },
          root: {
            padding: "0px",
            fontSize: "16px",
            borderRadius: "32px",
            "&:active": {
              color: "#051d39",
              "& .MuiSelect-select": {
                color: "#051d39", // Изменяем размер шрифта внутреннего MuiTypography
              },
            },
            "& .MuiSelect-select": {
              alignContent: "center", // Изменяем размер шрифта внутреннего MuiTypography
            },
            "&:hover": {
              borderColor: "#c1c1c4",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          input: {
            color: "#051d39",
            padding: "14.5px 24px",
          },
          root: {
            fontSize: "14px",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            flexDirection: "row !important",
            color: "#051D39",
            boxShadow: "none",
            height: "32px",
          },
          content: {
            margin: 0,
          },
          "&.Mui-expanded": {
            backgroundColor: "darkgrey", // Цвет фона при раскрытии
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            borderRadius: "50%",
            backgroundColor: "#fff",
            border: "1px solid var(--main)",
            width: 20,
            height: 20,
          },
          rail: {
            backgroundColor: "#CFD9E8",
            opacity: 1, // Цвет левой части
          },
          track: {
            backgroundColor: "#1876D2",
            opacity: 1, // Цвет правой части
          },
        },
      },
      "& .MuiSlider-thumb": {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
          boxShadow: "inherit",
        },
        "&::before": {
          display: "none",
        },
      },
      "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#52af77",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&::before": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
          transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
        },
        "& > *": {
          transform: "rotate(45deg)",
        },
      },
    },
  });

  useEffect(() => {
    if(!token) return
    isOrganizationBlocked().then(data => {
      setIsOrganizationBlockedState(data.data)
    })
  }, [token])

  const renderNewRoutes = () => {
    return (
      <Routes>
        {blockedOrganizationRoutes.map((routeConfig) => (
          <Route key={Date.now()} exact={routeConfig.exact} path={routeConfig.path} element={routeConfig.component} />
        ))}
        <Route path={PATHS.ALL_ALIENS} element={<Navigate to={PATHS.CABINET} />} />
      </Routes>
    );
  };

  const renderProtectedRoutes = () => {
    return (
      <Routes>
        {protectedRoutes.map((routeConfig) => (
          <Route key={Date.now()} exact={routeConfig.exact} path={routeConfig.path} element={routeConfig.component} />
        ))}
        <Route
          path={PATHS.SLASH}
          element={<Navigate to={roleId !== 4 && facilitiesAvailable ? PATHS.OBJECTS_MAP : PATHS.LIST_TASKS} />}
        />
        <Route path={PATHS.ALL_ALIENS} element={<Navigate to={"/"} />} />
      </Routes>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <AlertNewVersion setOpen={setOpen} open={open} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Can I="manage" a="auth">
          <Layout>
            {isOrganizationBlockedState ? renderNewRoutes() : renderProtectedRoutes()}
          </Layout>
        </Can>

        <Can I="manage" a="notAuth">
          <Routes>
            {NonAuthRoutes.map((routeConfig) => (
              <Route
                key={Date.now()}
                exact={routeConfig.exact}
                path={routeConfig.path}
                element={routeConfig.component}
              />
            ))}
            <Route path={PATHS.ALL_ALIENS} element={<PageNotFound />} />
            <Route path={PATHS.SLASH} element={<Navigate to={PATHS.LOGIN_ROUTE} />} />
          </Routes>
        </Can>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
