import React, { useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import InputText from "../InputText";
import { useLazyGetAddressSelectQuery } from "../../../middleware/addressDataApi";
import { useAppSelector } from "../../../hooks/redux";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"
import { useTranslation } from "react-i18next";

export const AddressInput = ({
  label,
  disabled = false,
  onChange,
  error,
  value,
  placeholder,
  shouldSetCoordinates,
  ...rest
}) => {

  const [inputValue, setInputValue] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const [getAddressSelect] = useLazyGetAddressSelectQuery();
  const { addressData } = useAppSelector(state => state.addressData);
  const getRequestNewValue = (newValue) => {
    
    const { data } = newValue || {};
    const requestData = {
      city: data?.city || "",
      street: data?.street || "",
      building: data?.house || "",
    };
    if (shouldSetCoordinates) {
      requestData.coordinates = {
        longitude: data?.geo_lon,
        latitude: data?.geo_lat,
      };
    }
    return requestData;
  };

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      getAddressSelect("");
      return undefined;
    }

    if (active) {
      getAddressSelect(inputValue)
    }

    return () => {
      active = false;
    };
  }, [inputValue, getAddressSelect]);
  console.log("placeholder", placeholder)
  return (
    <Autocomplete
      id="address"
      getOptionLabel={(option) => option.value}
      options={addressData?.suggestions || []}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      noOptionsText="Введите адрес"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      IconComponent={() =>
        <Box sx={{ mr: "24px" }}>
          {isOpen ? (
            <img src={selectArrowUp} />
          ) : (
            <img src={selectArrowDown} />
          )
          }
        </Box>}
      defaultValue={() => (
        value?.city ? (
          {
            data: {},
            unrestricted_value: "",
            value: `${value.city} ${value.street || ""} ${value.building || ""}`
          }
        ) : null
      )}
      onChange={(event, newValue) => {
        onChange(getRequestNewValue(newValue));
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      {...rest}
      renderInput={(params) => (
        <InputText
          placeholder={placeholder}
          {...params}
          type={"text"}
          label={label}
          disabled={disabled}
          error={error}

          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <Box sx={{ cursor: "pointer" }} onClick={() => { setIsOpen((prev) => !prev) }}>
                  {isOpen ? (
                    <img src={selectArrowUp} />
                  ) : (
                    <img src={selectArrowDown} />
                  )
                  }
                </Box>
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};