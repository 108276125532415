import React, {useState} from 'react';
import {ToggleButtonGroup, ToggleButton, Typography, FormControl} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function ToggleButtonsGroup({options, label, onChange, error, value, CustomOptionItem,disabled}) {
    const {t} = useTranslation()
    const [alignment, setAlignment] = useState(value);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
        onChange(newAlignment)
    };

    return (
        <FormControl fullWidth error={!!error}>
            <Typography sx={{mb: '10px'}} className="input-block-title" >{t(label)}</Typography>
            <ToggleButtonGroup
                sx={{
                    display: 'flex',
                    padding: '8px',
                    justifyContent: 'space-between',
                    border: '1px solid var(--border)',
                    borderRadius: '32px',
                    minWidth: "850px"
                }}
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label={t(label)}
            >
                {options.map((option, i) => (
                    CustomOptionItem ?
                        <CustomOptionItem disabled ={disabled} key={i} {...option} selected={value === option.value || value === option}/>
                        : <ToggleButton disabled ={disabled} key={i} value={option.value || option} aria-label={option.value || option}>
                            {option.value || option}
                        </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </FormControl>
    );
}