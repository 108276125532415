import React, { useState } from "react";
import "./styles.css";
import { Box, Tooltip, tooltipClasses, Typography } from '@mui/material';
import DonutChart from "../../components/DonutChart";
import TaskStateList from "../../components/TaskStateList/TaskStateList";
import { getDataToChart } from "../../../helpers/helper";
import { styled } from "@mui/material/styles";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dayjs from "dayjs";
import "dayjs/locale/ru";

export const TimelineThing = ({ rows, isEdit, selectedItemId,objectId, setSelectedItemId, expandNames, newItems, setData, items, setitems, startDate, endDate }) => {



  const generateMonthsInRange = (start, end) => {
    const months = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      let fulldate = currentDate
      months.push({
        fullDate: new Date(currentDate),
        month: currentDate.getMonth(), // 0-11
        year: currentDate.getFullYear(),
        monthName: currentDate.toLocaleString('default', { month: 'long' }),

      });
      // Переход к следующему месяцу
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  };
  function replaceMonthAndYear(monthYearStr, dateStr) {
    const currentDate = dayjs(dateStr);

    // Извлекаем месяц и год из строки
    const [month, year] = monthYearStr.split('-').map(Number);
    
    // Создаем новый объект даты с измененными месяцем и годом
    const newDate = currentDate.set('month', month).set('year', year);

    // Получаем количество дней в новом месяце
    const lastDayOfNewMonth = newDate.endOf('month').date();
    
    // Проверяем, если текущий день превышает количество дней в новом месяце
    if (currentDate.date() > lastDayOfNewMonth) {
        // Устанавливаем день на последний день нового месяца
        return newDate.date(lastDayOfNewMonth);
    }
    
    return newDate;

  }
  const checkforRepit = (prev, newItem) => {
    const index = prev.findIndex(item => item.id === newItem.id);

    if (index !== -1) {
      // Если объект найден, обновляем его данные
      prev[index] = { ...prev[index], ...newItem };
    } else {
      // Если объекта нет, добавляем новый объект в массив
      prev.push(newItem);
    }

    return prev;
  }
  function isDateInRange(dateRanges, targetRange) {

    const targetStart = new Date(targetRange.startDate);
    const targetEnd = new Date(targetRange.endDate);
    // Итерация по каждому диапазону в массиве
    for (const range of dateRanges) {
      const rangeStart = new Date(range.startDate);
      const rangeEnd = new Date(range.endDate);

      // Проверяем, пересекаются ли диапазоны
      if (targetStart < rangeEnd && targetEnd > rangeStart) {
        return true; // Найдено пересечение
      }
    }
    return false; // Нет пересечения с ни одним диапазоном
  }
  function isDateInRange1(dateRanges, targetRange) {

    const targetStart = new Date(targetRange.startDate);
    const targetEnd = new Date(targetRange.endDate);
    // Итерация по каждому диапазону в массиве
    for (const range of dateRanges) {
      const rangeStart = new Date(range.startDate);
      const rangeEnd = new Date(range.endDate);

      // Проверяем, пересекаются ли диапазоны
      if (targetStart < rangeEnd && targetEnd > rangeStart) {
        return { startDate: range.startDate, endDate: range.endDate, isGood: true }  // Найдено пересечение
      }
    }
    return { startDate: targetRange.startDate, endDate: targetRange.endDate, isGood: false }; // Нет пересечения с ни одним диапазоном
  }
  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    setSelectedItemId({ id: -1, periodsDates: [] })
    if (!destination) return; // Если элемент не был перемещен куда-либо

    const sourceGroup = source.droppableId;
    const destinationGroup = destination.droppableId;

    let sourceelements = sourceGroup.split('_');
    sourceelements.pop();
    let sourceId = sourceelements.join('_');
    let destinationelements = destinationGroup.split('_');
    let lastData = destinationelements.pop();
    let destinationId = destinationelements.join('_');

    if (sourceId === destinationId) {

      const sourceItem = items.find((item) => item.id == draggableId);

      if (sourceItem) {
        const itemStartDate = new Date(replaceMonthAndYear(lastData, sourceItem.startDate));
        const itemEndDate = new Date(replaceMonthAndYear(lastData, sourceItem.endDate));
        const limitperiodsDate = sourceItem.periodsDates
        
        let isGoodToMove = isDateInRange1(limitperiodsDate, { startDate: itemStartDate, endDate: itemEndDate })
        console.log("isGoodToMove",lastData, sourceItem.startDate, sourceItem.endDate,itemStartDate,itemEndDate)
        if (isGoodToMove?.isGood) {
          const newitem = { equipmentPlanPeriodId: draggableId, startDate: isGoodToMove?.startDate, endDate: isGoodToMove?.endDate }
          setData(((prevItems) => {
            const itemIndex = prevItems.findIndex(item => item.equipmentPlanPeriodId === draggableId);

            if (itemIndex !== -1) {
              // Если элемент с таким id уже существует, обновляем его
              const updatedItems = [...prevItems];
              updatedItems[itemIndex] = newitem;
              return updatedItems;
            } else {
              // Если элемента с таким id нет, добавляем новый
              return [...prevItems, newitem];
            }
          }))
          setitems((prevData) => prevData.map(item =>
            item.id === draggableId ? { ...item, startDate: isGoodToMove?.startDate, endDate: isGoodToMove?.endDate } : item
          ))
        } else {
          console.warn('Объект выходит за пределы установленных лимитов.');
        }
      }

      // Проверим границы limitStartDate и limitEndDate

    }
  };

  const onDragStart = (result) => {
    const { source, draggableId } = result;
    const sourceGroup = source.droppableId;
    let sourceelements = sourceGroup.split('_');
    sourceelements.pop();
    let sourceId = sourceelements.join('_');
    const sourceItem = items.find((item) => item.id == draggableId);
    setSelectedItemId({ id: sourceId, periodsDates: sourceItem?.periodsDates });

  };
  const handleItemClick = (id, item) => {
    setSelectedItemId(selectedItemId === id ? { id: -1, periodsDates: [] } : { id: id, periodsDates: item?.periodsDates });
  };
  const getEquipmentCount = (groupId, month, year, inPeriod, isEquipRow) => {
    const targetData = items.filter(item => {
      const itemStartDate = new Date(item.startDate);
      return item.group === groupId &&
        !expandNames?.includes(item?.group) &&
        itemStartDate.getFullYear() === year &&
        itemStartDate.getMonth() === month;
    });
    if (!isEquipRow) {
      if (targetData.length > 0) {
        if (targetData[0]?.jobChart) {
          
          const itemType = targetData[0]?.group?.includes('kind') ? !targetData[0]?.group?.includes('group') ? 'kind' : 'group' : "system";
          const filterData = targetData[0]?.group.split("_")[1]
          const start = targetData[0]?.startDate
          const end = targetData[0]?.endDate
          let filter = `?ForPlanningOnly=true&facilityName=${objectId}&startDate=${start}&endDate=${end}`
          switch (itemType) {
            case "system":
              filter+=`&EquipmentKindId=${filterData}`
              break;
            case "kind":
              filter+=`&EquipmentType=${filterData}`
              break;
            case "group":
              filter+=`&EquipmentGroupId=${filterData}`
              break;
            default:
              filter += ""
          }
          const data = getDataToChart({
            jobChart: targetData[0]?.jobChart,
            action: filter,
          }) || {};
          const taskstates = data?.filter(
            (task) =>
              task.name != "totalJobsAmount" && task.name != "total_jobs_amount"
          );

          const text = data?.filter(
            (task) =>
              task.name == "totalJobsAmount" || task.name == "total_jobs_amount"
          )[0]?.value;

          const HtmlTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
          ))(() => ({
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: "var(--white)",
              p: "10px",
            },
          }));
          const isExpired = new Date(targetData[0]?.endDate) < new Date()
          return (
            <HtmlTooltip
              arrow
              placement="left-start"
              title={
                <TaskStateList
                  data={taskstates || []}
                  fontSize="12px"
                  lineHeight="18px"
                  height="8px"
                  width="8px"
                />
              }
            >
              <Box sx={{ filter: isExpired ? 'grayscale(100%)' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DonutChart
                  data={taskstates || []}
                  width={46}
                  height={46}
                  radius={23}
                  thickness={6}
                  isText={text || "0"}
                />
              </Box>
            </HtmlTooltip>
          );
        } else if (targetData[0].jobCount != null) return <Box sx={{padding:"0px 10px"}}><Typography sx={{border:"1px solid green",fontSize:"16px",
fontWeight:500,borderRadius:"8px",height:"100%",padding:"10px 0px"}}>{targetData[0].jobCount}</Typography></Box> 
          else return <></>
      } else return <></>
    }
    else {
      if (targetData.length > 0) {
        return (
          <Droppable type={groupId} direction='horizontal' style={{}} droppableId={`${groupId}_${month}-${year}`} key={`${groupId}_${month}-${year}`}>
            {(provided) => (
              <Box
                //  key={`${groupId}_${month}-${year}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ display: "flex", width: "calc(100% - 20px)", ml: "10px", height: "40px" }}
              >
                {targetData?.map((target, index) => {

                  return (

                    <Draggable draggableId={target.id} key={target.id} index={index} isDragDisabled={(!target?.isReadOnly && isEdit) ? false : true}>
                      {(provided) => (
                        <Tooltip target={target.id} title={target.regulationName}>
                          <Box
                            key={target.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // onClick={() => handleItemClick(groupId,target)}
                            flex={1}
                            style={{ ...provided.draggableProps.style }}
                            sx={{

                              backgroundColor: target.color,
                              
                              borderRadius: "8px",
                              borderRight: `${index != targetData.length - 1 ? "1px" : "2px"} solid ${target.borderColor}`,
                              borderLeft: `${index != 0 ? "1px" : "2px"} solid ${target.borderColor}`,
                              borderTop:`2px solid ${target.borderColor}`,
                              borderBottom:`2px solid ${target.borderColor}`,
                              borderTopRightRadius: index != targetData.length - 1 ? 0 : "8px",
                              borderBottomRightRadius: index != targetData.length - 1 ? 0 : "8px",
                              borderTopLeftRadius: index != 0 ? 0 : "8px",
                              borderBottomLeftRadius: index != 0 ? 0 : "8px",
                              filter: target?.isReadOnly ? 'grayscale(100%)' : 'none',
                            }}
                          //onClick={() => handleItemClick(groupId, target)}
                          >

                          </Box>
                        </Tooltip>
                      )}
                    </Draggable>

                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )
      } else {
        return <Droppable type={groupId} style={{}} droppableId={`${groupId}_${month}-${year}`} key={`${groupId}_${month}-${year}`}>
          {(provided, snapshot) => (
            <Box
              //  key={`${groupId}_${month}-${year}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ backgroundColor: !inPeriod ? "transparent" : snapshot.isDraggingOver ? 'lightblue' : 'transparent', display: "flex", width: "calc(100% - 20px)", ml: "10px", height: "40px" }}
            >
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      }
    }
  };
  const monthsInRange = generateMonthsInRange(new Date(startDate), new Date(endDate));

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragEnd} onDragUpdate={(update) => {
      const { destination } = update;

      if (destination && update.source.droppableId === destination.droppableId) {
        // Запрещаем перемещение по вертикали
        // Позиция destination не меняется
        update.destination.index = update.source.index;
      }
    }}>

      {rows.map(row => {
        const isInSelectedRow = selectedItemId?.id == row.id || selectedItemId?.id == -1
        const isTreeSelectedRow = expandNames?.includes(row.id)
        const isEquipmentRow = row?.id.split("_")[0] == "equipmentId"
        return <Box

          display="flex"
          key={row.id}
          sx={{height:"60px"}}
        >

          {monthsInRange.map(({ month, year, fullDate }) => {
            let inPeriod = isDateInRange(selectedItemId?.periodsDates, { startDate: fullDate, endDate: fullDate })
            if (selectedItemId?.periodsDates?.length == 0) {
              inPeriod = true
            }

            return <Box
              key={`${row.id}_${month}-${year}`}
              flexGrow={1}
              sx={{border:`${0.5}px solid ${(inPeriod && isInSelectedRow) ? "#cfd9e8" : "rgba(128,128,128,0.7)"}`, width: "96px", flexShrink: "0", alignContent: "center", backgroundColor: (inPeriod && isInSelectedRow) ? isTreeSelectedRow ? "#EEF4FD" : "white" : "rgba(128,128,128,0.7)", }}
              textAlign="center"

            >
              {getEquipmentCount(row.id, month, year, inPeriod && isInSelectedRow, isEquipmentRow)}

            </Box>


          })}
        </Box>
      })}

    </DragDropContext>
  );
};
