export const getRequestData = (data) => {
  const { category, tariff } = data;
  return { ...data, category: category === "Некоммерческий" ? 0 : category, tariff: tariff === "До 500" ? 0 : tariff };
};

export const getRequestDataFilesForAdd = (entityId, entityType, file) => {
  const formData = new FormData();

  if (!file) {
    return formData;
  }

  formData.append(`entityFileList[0].EntityId`, entityId);
  formData.append(`entityFileList[0].EntityType`, entityType);
  formData.append(`entityFileList[0].File`, file);
  formData.append(`entityFileList[0].ActionType`, "Add");

  return formData;
};
