import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Box, Typography, FormControlLabel, Checkbox, InputLabel, TextField, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import InputText from "../../components/InputText";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppSelector } from "../../../hooks/redux";
import SelectSimple from "../../components/SelectSimple";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import {
  useLazyGetRoomsPlumesQuery,
  useGetRoomsPlumesQuery,
  useLazyGetRoomsAddressSelectionQuery
} from "../../../middleware/equipmentsApi";
import { formFields } from "./form-config";

export const DateFormatCell = ({ item }) => {
  const dateFormat = "DD.MM.YYYY, HH:mm";
  const content = dayjs(item).format(dateFormat);

  return <Typography className="standart_lg">{content}</Typography>;
};

export const SimpleTextCell = ({ item }) => {
  const content = item;

  return <Typography className="standart_lg">{content}</Typography>;
}
export const SelectSimpleMessageCodes = (props) => {
  const { messageCodeElems } = useAppSelector((state) => state.messageCode);
  console.log("messageCodeTypes",messageCodeElems)

  return <SelectSimple options={messageCodeElems} displayEmpty={true}  {...props} />;
};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} displayEmpty={true}  {...props} />;
};
export const RoomAdressesSelectGrouping = (props) => {
  const { control, setValue } = props
  const { floor, roomAddressId } = useWatch({
    control,
  });
  const [searchValue, setSearchValue] = useState("");
  const [datarooms, setdatarooms] = useState([]);
  const [
    getRoomsAddressSelection,
    { isError: isErrorGetRoomsAddressS, isLoading: isLoadingGetRoomsAddressS },
  ] = useLazyGetRoomsAddressSelectionQuery();
  useEffect(() => {
    if (floor) {
      getRoomsAddressSelection({ floorInfoId: floor, searchName: searchValue }).then(
        ({ data, isSuccess }) => {
          setdatarooms(data)

        }
      );
    }

  }, [floor, searchValue]);


  // useEffect(() => {

  //   if (roomAddressId) {
  //     const findRoom = datarooms.find((item) => item.value == roomAddressId)
  //     console.log("roomAddressId", datarooms)
  //     if (findRoom) {
  //       setValue(formFields.integrationNumber, findRoom.integrationNumber)
  //     }
  //   }

  //   //
  // }, [datarooms, roomAddressId]);

  // console.log('datarooms',props)
  return (
    <Box className="medium_container">
      <SelectSimpleSearch
        {...props}
        setSearchValue={setSearchValue}
        displayEmpty={true}
        searchValue={searchValue}
        options={datarooms}
      />
    </Box>
  );
};
export const InputAutocompletePlume = ({
  label,
  disabled = false,
  onChange,
  error,
  setValue,
  resetField,
  ...props
}) => {
  const { t } = useTranslation();
  const { control, value } = props
  const { roomAddressId, plume } = useWatch({
    control,
  });

  const [searchValue, setSearchValue] = useState("");
  const [optionsName, setoptionsName] = useState([]);

  let propsAutoComple = {};

  const [
    getRoomsPlumesSelection,
    { isError: isErrorGetRoomsPlumes, isLoading: isLoadingGetRoomsAddressPlumes },
  ] = useLazyGetRoomsPlumesQuery();
  useEffect(() => {
    if (roomAddressId) {
      getRoomsPlumesSelection({ roomAddressId, plume: searchValue }).then(
        ({ data, isSuccess }) => {
          setoptionsName(data)

        }
      );
    } else {

      setoptionsName([{ name: value, value: value }])
    }

  }, [roomAddressId, searchValue]);


  const handleInputChange = (newInputValue) => {

    onChange(newInputValue);
    setSearchValue(newInputValue)
  };

  return (
    <>
      <InputLabel sx={{ mb: "8px" }}>{t(label)}</InputLabel>
      <Autocomplete
        freeSolo
        getOptionLabel={(option) => {
          if (option?.name)
            return option?.name
          if (typeof option === 'string')
            return option
          return ''
        }}
        options={optionsName}
        value={optionsName.find(option => option.name === value) || null}
        onInputChange={(event, newInputValue) => {
          console.log("option?.name1", newInputValue)
          handleInputChange(newInputValue.split("#").shift().trim())
        }

        }
        renderOption={(props, option) => (

          <Box component="li" {...props} key={option.value}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ padding: 0 }}
            error={!!error}
          />
        )}
        className="block_with_img-input"
      />
    </>
  );
};

export const RoomPlumesSelectGrouping = (props) => {
  const { control } = props
  const { roomAddressId } = useWatch({
    control,
  });
  const [searchValue, setSearchValue] = useState("");
  const [dataplumes, setdataplumes] = useState([]);
  const [
    getRoomsPlumesSelection,
    { isError: isErrorGetRoomsPlumes, isLoading: isLoadingGetRoomsAddressPlumes },
  ] = useLazyGetRoomsPlumesQuery();
  useEffect(() => {
    if (roomAddressId) {
      getRoomsPlumesSelection({ roomAddressId, plume: searchValue }).then(
        ({ data, isSuccess }) => {
          setdataplumes(data)

        }
      );
    }

  }, [roomAddressId, searchValue]);

  return (
    <Box className="medium_container">
      <SelectSimpleSearch
        {...props}
        setSearchValue={setSearchValue}
        displayEmpty={true}
        searchValue={searchValue}
        options={dataplumes}
      />
    </Box>
  );
};
export const TextCellWithEdit = ({ item }) => {
  const [isEditing, setIsEditing] = useState(false); // Добавлено состояние для отслеживания режима редактирования
  const [newValue, setNewValue] = useState(item); // Добавлено состояние для отслеживания измененного значения

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setNewValue(event.target.value);
  };

  /* const handleInputBlur = () => {
    setNewValue(newValue);
  }; */

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {isEditing ? (
        <InputText
          style={{
            height: "45px",
            padding: 0,
            margin: "0 5px",
          }}
          type="text"
          value={newValue}
          onChange={handleInputChange}
        />
      ) : (
        <Typography className="standart_lg" onClick={handleEditClick}>
          {newValue}
        </Typography>
      )}
      <EditOutlinedIcon onClick={handleEditClick} />
    </Box>
  );
};

export const ExecutiveBlockCell = ({ item }) => {
  const { executor, executorPosition, executorOrganization } = item || {};

  return (
    <Box>
      <Typography className="standart_lg">{executor}</Typography>
      <Typography className="additional_grey">{executorPosition}</Typography>
      <Typography className="additional_grey">
        {executorOrganization}
      </Typography>
    </Box>
  );
};

export const LinkTextCell = ({ item }) => {
  const { id, name } = item;
  const [openTaskDialog, setOpenTaskDialog] = useState(false);

  return (
    <>
      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={id}
        />
      )}
      <Typography
        className="table-row-cell-link standart_lg"
        onClick={setOpenTaskDialog.bind(null, true)}
      >
        {name}
      </Typography>
    </>
  );
};

export const JobsBlockCell = ({ item }) => {
  const { description, checklists, lastComment } = item || {};
  return (
    <Box>
      <Typography className="standart_lg" sx={{ marginBottom: "16px" }}>{lastComment || "Нет коментариев"}</Typography>
      <Typography className="standart_lg">{description}</Typography>
      {checklists?.length &&
        checklists.map(({ id, description, isCompleted }) => (
          <FormControlLabel
            key={id}
            disabled
            control={<Checkbox defaultChecked={isCompleted} />}
            label={description}
            sx={{ display: "block" }}
          />
        ))}
    </Box>
  );
};
