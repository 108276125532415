import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../hooks/redux";
import { useLazyGetFacilitiesListOptionQuery } from "../../../../middleware/facilitiesApi";
import ObjectPanel from "./ObjectPanel";
import AddObjectModal from "./AddObjectModal";
import PlusIcon from "../../../components/icons/PlusIcon";
import "./styles.css";

const ObjectsList = ({ gridTypeValue, headerRef, searchFacility }) => {
  const { t } = useTranslation();
  const { openSidebar } = useAppSelector((state) => state.settings);
  const [facilities, setFacilities] = useState([]);
  const [divideFacilities, setDivideFacilities] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isVisibleAddObjectModal, setIsVisibleAddObjectModal] = useState(false);
  const [isAddedNewObject, setIsAddedNewObject] = useState(false);
  const [activeObjectId, setActiveObjectId] = useState(-1);
  const [getFacilitiesListOption, { isLoading, refetch }] = useLazyGetFacilitiesListOptionQuery();

  const handleActiveObjectChange = (newActiveObjectId) => {
    setActiveObjectId(newActiveObjectId);
  };

  const handleCloseAddObjectModal = () => {
    setIsVisibleAddObjectModal(false);
  };

  const splitIntoFour = (arr) => {
    return arr.reduce(
      (acc, _, i) => {
        const index = i % 4;
        if (!acc[index]) {
          acc[index] = [];
        }
        acc[index].push(_);
        return acc;
      },
      { 0: [], 1: [], 2: [], 3: [] }
    );
  };

  useEffect(() => {
    getFacilitiesListOption({ facilityName: searchFacility, pageNumber: 0 })
      .then((res) => {
        setFacilities(res.data.data);
        setHasMore(res.data.hasMoreData);
      })
      .catch((error) => console.log(error));
  }, [searchFacility]);

  useEffect(() => {
    const divideFacilities = splitIntoFour(facilities);
    setDivideFacilities(divideFacilities);
  }, [facilities]);

  const loadMoreFacilities = () => {
    getFacilitiesListOption({ facilityName: searchFacility, pageNumber: pageNumber + 1 })
      .then((res) => {
        setPageNumber((prev) => prev + 1);
        setFacilities((prev) => [...prev, ...res.data.data]);
        setHasMore(res.data.hasMoreData);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!isAddedNewObject) return;

    for (let i = 0; i <= pageNumber; i++) {
      getFacilitiesListOption({ facilityName: searchFacility, pageNumber: i })
        .then((res) => {
          setPageNumber((prev) => prev + 1);
          setFacilities((prev) => [...prev, ...res.data.data]);
          setHasMore(res.data.hasMoreData);
        })
        .catch((error) => console.error(error));
    }
    setIsAddedNewObject(false);
  }, [isAddedNewObject]);

  const clearState = () => {
    setPageNumber(0)
    setFacilities([])
  }

  return (
    <>
      {!isLoading ? (
        <Box
          className="objects-list"
          sx={{
            gap: openSidebar ? "20px" : "30px",
            overflow: "auto",
            height: `calc(100vh - ${headerRef?.current?.offsetHeight}px - 200px)`,
          }}
        >
          <ul className="object-list-column">
            <li>
              <Box
                className="add-object"
                sx={{ height: gridTypeValue === 0 ? "264px" : "144px" }}
                onClick={() => setIsVisibleAddObjectModal(true)}
              >
                <Typography sx={{ fontSize: "20px" }}>{t("button:add")}</Typography>
                <PlusIcon />
              </Box>
            </li>
            {divideFacilities[3]?.map((facility, index) => (
              <li key={index}>
                <ObjectPanel
                  key={facility.id}
                  gridTypeValue={gridTypeValue}
                  facility={facility}
                  activeObjectId={activeObjectId}
                  isOpen={activeObjectId === facility.id}
                  onOpenPanel={handleActiveObjectChange}
                />
              </li>
            ))}
          </ul>
          <ul className="object-list-column">
            {divideFacilities[0]?.map((facility, index) => (
              <li key={index}>
                <ObjectPanel
                  key={facility.id}
                  gridTypeValue={gridTypeValue}
                  facility={facility}
                  activeObjectId={activeObjectId}
                  isOpen={activeObjectId === facility.id}
                  onOpenPanel={handleActiveObjectChange}
                />
              </li>
            ))}
          </ul>
          <ul className="object-list-column">
            {divideFacilities[1]?.map((facility, index) => (
              <li key={index}>
                <ObjectPanel
                  key={facility.id}
                  gridTypeValue={gridTypeValue}
                  facility={facility}
                  activeObjectId={activeObjectId}
                  isOpen={activeObjectId === facility.id}
                  onOpenPanel={handleActiveObjectChange}
                />
              </li>
            ))}
          </ul>
          <ul className="object-list-column">
            {divideFacilities[2]?.map((facility, index) => (
              <li key={index}>
                <ObjectPanel
                  key={facility.id}
                  gridTypeValue={gridTypeValue}
                  facility={facility}
                  activeObjectId={activeObjectId}
                  isOpen={activeObjectId === facility.id}
                  onOpenPanel={handleActiveObjectChange}
                />
              </li>
            ))}
          </ul>
        </Box>
      ) : (
        <Box className="loader">
          <CircularProgress />
        </Box>
      )}
      {hasMore && (
        <Button
          className="btn btn-primary-fill"
          sx={{ margin: "24px 0px", width: "50%", placeSelf: "center" }}
          onClick={loadMoreFacilities}
        >
          Загрузить еще
        </Button>
      )}
      {isVisibleAddObjectModal && (
        <AddObjectModal
          isVisible={isVisibleAddObjectModal}
          handleClose={handleCloseAddObjectModal}
          isNeedRefetchData={() => setIsAddedNewObject(true)}
          clearState={clearState}
        />
      )}
    </>
  );
};

export default ObjectsList;
