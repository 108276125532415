import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  Select,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";
import DropdownIcon from "../icons/Cabinet/DropdownIcon";
import ClearIcon from "@mui/icons-material/Clear";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 13;
const SEARCH_HEIGHT = 72;
const SelectGroupingSystemPPZInBuilt = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  isTableheader,
  defaultValue = 0,
  bgcolor,
  height = "",
  isSystemPPZ = false,
  disabled = false,
  placeholder,
  writeSearchDataToLocalStorage,
  storageValue,
  storageName,
  name,
  resetField,
  ...rest
}) => {
  const { t } = useTranslation();

  const [updatedOptions, setUpdatedOptions] = useState([]);
  const allOptionItemsCount = useMemo(() => {
    let totalCount = 0;
    totalCount += updatedOptions?.length;
    if (isTableheader) totalCount++;
    updatedOptions?.map((parent) => {
      if (parent?.open) totalCount += parent?.items?.length;
    });

    return totalCount;
  }, [updatedOptions]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight:
          allOptionItemsCount >= 4
            ? (ITEM_HEIGHT + ITEM_PADDING_TOP) * 4 + SEARCH_HEIGHT
            : (ITEM_HEIGHT + ITEM_PADDING_TOP) * allOptionItemsCount +
              SEARCH_HEIGHT,
      },
    },
  };
  const [selectedValue, setSelectedValue] = useState(
    defaultValue === 0 ? value : defaultValue
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSearchValue("");
  };

  const handleClearValue = () => {
    setSelectedValue("");
    resetField(name)
  };

  useEffect(() => {
    setUpdatedOptions(options);
    if (options?.length) {
      if (Number(value)) {
        const selectedItem = options
          ?.reduce((sum, { items }) => [...sum, ...items], [])
          ?.find(({ id }) => id === value);

        setSelectedValue(JSON.stringify(selectedItem) || value);
        writeSearchDataToLocalStorage && writeSearchDataToLocalStorage(storageValue, selectedItem || value)
      } else {
        setSelectedValue(
          value || defaultValue === 0 ? value : JSON.stringify(defaultValue)
        );
        writeSearchDataToLocalStorage && writeSearchDataToLocalStorage(storageValue, defaultValue || value)
      }
    }
  }, [options, value, defaultValue]);

  return (
    <Box sx={{ position: "relative" }} className="input_text">
      {!isTableheader && <InputLabel sx={{ mb: "8px" }}>{t(label)}</InputLabel>}

      <FormControl
        fullWidth
        error={!!error && !disabled}
        sx={{ background: bgcolor ? bgcolor : "inherit", height: height }}
        {...rest}
      >
        <Select
          value={selectedValue}
          renderValue={(selected) => {
            return selected ? (
              <Tooltip
                title={
                  selected === 0 || selected === "все"
                    ? "все"
                    : selected
                      ? JSON.parse(selected).name
                      : ""
                }
              >
                <Box
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {selected === 0 || selected === "все"
                    ? "все"
                    : selected
                      ? JSON.parse(selected).name
                      : ""}
                </Box>
              </Tooltip>
            ) : (
              "Система"
            );
          }}
          disabled={disabled}
          input={<OutlinedInput />}
          onChange={onChange}
          MenuProps={MenuProps}
          displayEmpty={isTableheader ? true : false}
          inputProps={{ "aria-label": "Without label" }}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          placeholder={placeholder}
          IconComponent={() => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: !disabled ? "pointer" : "default",
              }}
              onClick={() => {
                if(disabled) return;
                selectedValue ? handleClearValue() : setIsOpen((prev) => !prev);
              }}
            >
              {selectedValue ? <ClearIcon sx={{ fontSize: 22 }} /> : <DropdownIcon />}
            </Box>
          )}
          sx={{
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              height: 0,
              color: "var(--additionalGrey)",

              fontSize: "16px",
              fontWeight: "500",
            },
            maxHeight: "56px",
          }}
          {...rest}
        >
          <MyListSubheaderSearch
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          {isTableheader && (
            <MenuItem
              disabled
              value=""
              sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
            >
              {t(label)}
            </MenuItem>
          )}
          {updatedOptions?.length ? (
            updatedOptions.map((option) => {
              const { open, items, id: parentId } = option || {};

              if (!items) {
                const { id, name } = option;
                return (
                  <MenuItem
                    key={id}
                    value={id}
                    sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
                  >
                    <ListItemText primary={name || ""} />
                  </MenuItem>
                );
              }
              if (items && items.length == 1) {
                const { id, name } = option;
                return (
                  <MenuItem
                    sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
                    key={id}
                    value={
                      JSON.stringify({
                        parentId: parentId,
                        id: items[0]?.id,
                        name: `${name}, ${items[0]?.name}`,
                      }) || ""
                    }
                  >
                    <ListItemText
                      sx={{
                        paddingLeft: "10px",
                      }}
                      primary={`${name}, ${items[0]?.name}` || ""}
                    />
                  </MenuItem>
                );
              }

              const Items = items?.length
                ? items.map((item) => {
                    const { id, name } = item;
                    return (
                      <MenuItem
                        key={id}
                        sx={{
                          display: `${open ? "block" : "none"}`,
                          paddingLeft: "70px",
                          height: ITEM_HEIGHT + ITEM_PADDING_TOP,
                        }}
                        value={
                          JSON.stringify({
                            ...item,
                            parentId: parentId,
                            id: id,
                            name: `${option.name}, ${name}`,
                          }) || ""
                        }
                      >
                        <Typography>{`${name}`}</Typography>
                      </MenuItem>
                    );
                  })
                : null;

              return [
                <MenuItem
                  key={option.id}
                  sx={{
                    height: ITEM_HEIGHT + ITEM_PADDING_TOP,
                    verticalAlign: "super !important",
                  }}
                  value={
                    JSON.stringify({
                      id: option.id,
                      parentId: parentId,
                      name: `${option.name}`,
                      isType: true,
                    }) || ""
                  }
                >
                  <MyListSubheader
                    key="MyListSubheader"
                    setOptions={setUpdatedOptions}
                    options={updatedOptions}
                    isSystemPPZ={isSystemPPZ}
                    setSelectedValue={setSelectedValue}
                    itemOption={option}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>,
                Items,
              ];
            })
          ) : (
            <MenuItem
              sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
              disabled={true}
            >
              <ListItemText primary={t("label:nothingFound")} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectGroupingSystemPPZInBuilt;
