import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import DateRangePickerNew from "../../../components/DateRangePickerNew/DateRangePickerNew";
import { dateFormat } from "../../../../constants";
import Switcher from "../../../components/Switcher";
import DownloadIconGrid from "../../../components/icons/Cabinet/DownloadIconGrid";
import PrintIconGrid from "../../../components/icons/Cabinet/PrintIconGrid";
import { useMarkInvoiceAsExcludedMutation } from "../../../../middleware/billingApi";
import { useAction } from "../../../../hooks/actions";

export const DateRangePicker = ({ placeholder, setValue, value }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (endDate != null) {
      if (startDate != null) setValue((prev) => [startDate, startDate]);
    }
  }, [endDate]);

  return (
    <DateRangePickerNew
      placeholder={placeholder}
      setValue={setValue}
      value={value}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  );
};

export const SimpleTextCell = ({ item, type, ...props }) => {
  const content = type === "date" ? dayjs(item).format(dateFormat) : type === "price" ? `${item} ₽` : item;

  return <Typography>{content}</Typography>;
};
export const BillCell = ({ row }) => {
  const { bill } = row;

  function downloadFile(fileName) {
    const a = document.createElement("a");
    a.href = fileName;
    a.target = "_blank";
    a.download = bill?.orderNumber; // Укажите имя файла, если необходимо
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function printFile(fileName) {
    const printWindow = window.open(fileName); // Открываем файл в новом окне
    printWindow.onload = function () {
      printWindow.print(); // Открываем диалог печати после загрузки
    };
  }


  return (
    <Box display="flex" justifyContent="space-between">
      <Typography>{bill?.orderNumber}</Typography>
      {bill?.orderFileLink &&
        <Box display="flex" gap="24px">
          <Box
            sx={{
              cursor: "pointer",
              transition: ".2s ease-out",
              "&:hover": { color: "var(--accent)", transition: ".2s ease-out" },
            }}
            onClick={() => printFile(bill?.orderFileLink)}
          >
            <PrintIconGrid />
          </Box>
          <Box
            sx={{
              cursor: "pointer",
              transition: ".2s ease-out",
              "&:hover": { color: "var(--accent)", transition: ".2s ease-out" },
            }}
            onClick={() => downloadFile(bill?.orderFileLink)}
          >
            <DownloadIconGrid />
          </Box>
        </Box>
      }
    </Box>
  );
};
// export const TotalBillCell = ({ row, ...props }) => {
//   const { totalBill,id } = row;
//   const joinToTotalBill = [
//     { status: true, name: "" },
//     { status: false, name: "" },
//   ];
  

//   const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
//   const [markInvoice, { error: isErrorMark, isSuccess: isSuccessMark }] =
//     useMarkInvoiceAsExcludedMutation();

//   // const markInvoiceObject = (event, id) => {

//   //   markInvoice({ id: id })
//   // }
//   useEffect(() => {
//     setIsSuccessSubmit(isSuccessMark);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isSuccessMark]);

//   useEffect(() => {
//     setIsErrorSubmit(isErrorMark);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isErrorMark]);
//   return (
//     <Box width="100%" sx={{ placeItems: "center" }}>
//       <Switcher style={{ placeContent: "center" }} onChange={(event) => markInvoiceObject(event, id)} value={totalBill?.includeInCommonOrder} options={joinToTotalBill} {...props} />
//     </Box>
//   );
// };
export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
