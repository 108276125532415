const RatingCell = ({ percent, isSmall }) => {
  const getColorByPercent = (percent) => {
    const getBgColorForBigPercent = () => {
      if (percent > 66 && percent <= 75)
        return `linear-gradient(to bottom, var(--succes), var(--accentYellow), var(--accentYellow),var(--accentYellow), var(--error), var(--error)), var(--error))`;

      if (percent > 75 && percent <= 85)
        return `linear-gradient(to bottom, var(--succes),  var(--succes), var(--accentYellow), var(--accentYellow),var(--accentYellow), var(--error), var(--error)), var(--error))`;

      if (percent > 85 && percent <= 100)
        return `linear-gradient(to bottom, var(--succes), var(--accentYellow), var(--accentYellow), var(--error))`;
    };

    if (percent >= 0 && percent <= 33) return "var(--error)";
    if (percent > 33 && percent <= 66) return "linear-gradient(to bottom, var(--accentYellow), var(--error) ";
    if (percent > 66) return getBgColorForBigPercent();
  };

  const containerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    height: isSmall ? "98px" : "218px",
    width: "10px",
    backgroundColor: "white",
    border: "1px solid #CFD9E8",
    borderRadius: "32px",
    overflow: "hidden",
  };

  const fillerStyle = {
    width: "100%",
    height: `${Math.round(+percent)}%`,
    background: `${getColorByPercent(percent)}`,
    borderRadius: "inherit",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle}>
      <div style={fillerStyle}></div>
    </div>
  );
};

export default RatingCell;
