import React from "react";
import { t } from "i18next";
import { formFields } from "./form-config";
import {
  SimpleTextCell,
  BillCell,SimpleHeader,TotalBillCell
} from "./Components";
import FormItem from "../../../components/FormItem";
import { SearchInbuilt } from "../../../components/SearchInbuilt";
import DateRangePickerNew from "../../../components/DateRangePickerNew/DateRangePickerNew";

export const getColumns = ({
  control,
  navigate,
  columnsFromStorage,
  searchObject,
  setsearchObject,
  daterange,
  setdaterange
}) =>
  [
    {
      id: formFields.index,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "60px",
      clickable: false,
      isVisible: true,//columnsFromStorage[formFields.index],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <SimpleHeader name={t("tableHeader:index_table")} />
      ),
    },
    {
      id: formFields.name,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: true,//columnsFromStorage[formFields.objectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.faciltyName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:object_name")}
              className="select_in_built small-light"
              searchValue={searchObject}
              changeSearchValue={setsearchObject}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.tarif,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      
      clickable: false,
      isVisible: true,// columnsFromStorage[formFields.tarif],
      RowCell: (props) => <SimpleTextCell  type="price" {...props} />,
      HeaderCell: () => (
        <SimpleHeader name={t("tableHeader:tarif")} />
      ),
    },
    {
      id: formFields.total,
      numeric: false,
      enableSorting: false,
      // label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: true,// columnsFromStorage[formFields.tarif],
      RowCell: (props) => <SimpleTextCell  type="price" {...props} />,
      HeaderCell: () => (
        <SimpleHeader name={t("tableHeader:cost")} />
      ),
    },

    {
      id: formFields.billingDate,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: true,// columnsFromStorage[formFields.debitingDate],
      RowCell: (props) => <SimpleTextCell type="date" {...props} />,
      HeaderCell: () => (
        <div
          style={{
            width: "280px",
          }}
        >
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.billingDate}`}
            sx={{
              padding: "9px 0 9px 6px",
              width: "100%",
              "& .date-picker-input": {
                padding: "0"
              }
            }}
            input={(props) => (
              <DateRangePickerNew
                placeholder={t("tableHeader:pay_date")}
                setdaterange={setdaterange}
                daterange={daterange}
                {...props}
              />
            )}
          />
        </div>
      ),
    },
    {
      id: formFields.bill,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: true,// columnsFromStorage[formFields.bill],
      RowCell: (props) => <BillCell {...props} />,
      HeaderCell: () => (
        <SimpleHeader name={t("tableHeader:bill")} />
      ),
    },
    {
      id: formFields.totalBill,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: true,//columnsFromStorage[formFields.totalBill],
      //RowCell: (props) => <TotalBillCell {...props} />,
      HeaderCell: () => (
        <SimpleHeader name={t("tableHeader:total_bill")} />
      ),
    },
   
  ].filter(({ isVisible }) => isVisible);
