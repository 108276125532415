import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import { useLazyGetOrganizationByInnQuery } from "../../../middleware/apiOrganizations";
import { useLazyGetOrgInnQuery } from "../../../middleware/addressDataApi";

export const ClickableNameCell = ({ item }) => {
  const { name, action } = item || {};
  return (
    <Typography className="table-row-cell-link" onClick={action ? action : () => {}}>
      {name || ""}
    </Typography>
  );
};

export const IsBlockedOrganizationCell = ({ item }) => {
  const { isBlocked, action } = item || {};
  return (
    <Button
      className={`btn ${isBlocked ? "btn-primary-fill" : "btn-red-fill"}`}
      onClick={action ? action : () => {}}
    >
      {isBlocked ? "Разблокировать" : "Заблокировать"}
    </Button>
  );
};
export const InnNumberSearchInput = (props) => {
  const [getOrganizationByInn] = useLazyGetOrgInnQuery();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!searchValue.length) {
      return;
    }
    getOrganizationByInn(searchValue)
      .then((res) => {
        const suggests = res?.data?.suggestions;
        if (suggests && suggests?.length > 0) {
          let suggestArray = suggests.map((item) => {
            return { value: item.data.inn, name: item.value };
          });
          setOptions(suggestArray);
        } else {
          setOptions([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getOrganizationByInn, searchValue]);

  return <SelectSimpleSearch {...props} searchValue={searchValue} setSearchValue={setSearchValue} options={options} />;
};

export const InnNumber = (props) => {
  const [getOrganizationByInn] = useLazyGetOrganizationByInnQuery();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!searchValue.length) {
      return;
    }
    getOrganizationByInn(searchValue)
      .then((res) => {
        setOptions(res?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getOrganizationByInn, searchValue]);

  return <SelectSimpleSearch {...props} searchValue={searchValue} setSearchValue={setSearchValue} options={options} />;
};
