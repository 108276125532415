import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import closeicon from "../../../image/icons/closeInputFile.png"

import {
  useGetObjectsOrganizationQuery,
  useGetOrganizationQuery,
} from "../../../middleware/apiOrganizations";
import "./styles/style.css";
import Requisites from "./Requisites";
import Objects from "./Objects";
import { useEffect } from "react";
import { useAction } from "../../../hooks/actions";


const MyOrganizationDetails = ({
  open,
  setOpen,
  id,
  typeId,
  defaultValueTab = 1,
}) => {
  const { t } = useTranslation();

  const { setIsErrorSubmit } = useAction();

  const {
    data: organization,
    error: errorGetOrganization,
    isLoading: isLoadingOrganization,
    refetch: organizationRefetch,
  } = useGetOrganizationQuery(
    { id, typeId },
    {
      skip: !id,
    }
  );
  const {
    data: objects,
    error: errorGetObjects,
    isLoading: isLoadingObjects,
    refetch: objectsRefetch,
  } = useGetObjectsOrganizationQuery(id, { skip: !id });

  const error = errorGetOrganization || errorGetObjects;

  const handleClose = () => {
    setOpen(false);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const BasicTabs = () => {
    const [value, setValue] = React.useState(defaultValueTab);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab className="label" label={t("label:requisites")} />
            <Tab className="label" label={t("label:objects")} />
            {/*<Tab className="label" label={t("label:billing")} />*/}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {!isLoadingOrganization && organization && (
            <Requisites typeId={typeId} organization={organization} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!isLoadingObjects && (
            <Objects objects={objects} organization={organization} />
          )}
        </TabPanel>
         {/*<TabPanel value={value} index={2}>
          {!isLoadingOrganization && organization && (
            <Billing typeId={typeId} organization={organization} />
          )}
        </TabPanel>
        */}
      </Box>
    );
  };

  useEffect(() => {
    if (open) {
      organizationRefetch();
      objectsRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="modal select_columns"
      maxWidth="lg"
      fullWidth
      sx={{ "& .MuiDialog-paper": { height: "calc(100% - 64px)" } }}
    >
      <DialogTitle
        
        className="modal-title-between border_bottom"
      >
        <Box sx={{ width: "100%", alignContent: "center", textAlign: "center", ml: "40px" }}>
          <Typography className="modal-title">
            {organization?.name || ""}
          </Typography>
        </Box>

        <IconButton onClick={handleClose} sx={{marginBottom: "auto"}}>
          <Box
            component="img"
            src={closeicon}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent className="details-body">
        <BasicTabs />
      </DialogContent>
    </Dialog>
  );
};

export default MyOrganizationDetails;
