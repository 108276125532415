import { Contacts } from "../../../components/Contacts/Contacts";
import { MenuItemLink, MenuItemPopupHandler,MenuItemLinkInside } from "./Components";
import { PATHS } from "../../../../constants";
export const menuItems = [
  {
    id: 1,
    label: "label:aboutSystem",
    link: "https://vdpopro.agency/",
    Component: MenuItemLink,
  },
  {
    id: 2,
    label: "label:contacts",
    Popup: Contacts,
    Component: MenuItemPopupHandler,
  },
  {
    id: 3,
    label: "label:infocenter",
    link: PATHS.INFO_CENTER,
    Component: MenuItemLinkInside,
  },
  // {
  //   id: 4,
  //   label: "label:popularQuestions",
  //   link: "https://vdpopro.agency/#faq",
  //   Component: MenuItemLink,
  // },


]