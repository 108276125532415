import { t } from 'i18next';
import { ObjectsSelect } from '../EmployeeList/form-config';
import { ClickableNameCell } from '../MyOrganizations/Components';
import { ControllerStatusCell } from './Components';
import { SimpleTextCell } from '../TasksList/Components';

export const formFields = {
  facilitiesIds: "facilitiesIds",
};
export const modalConfig = [
  {
    id: 1,
    name: formFields.facilitiesIds,
    type: "select-multi",
    label: "label:objects",
  
    input: (props) => <ObjectsSelect {...props} />,
  },
];

export const columns = [
  {
    id: "name",
    label: t("tableHeader:controllerName"),
    numeric: false,
    clickable: false,
    enableSorting: false,
    size: "170px",
    RowCell: (props) => <SimpleTextCell {...props} />,
    HeaderCell: () => (
      <div
        style={{
          color: "var(--headers)",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {t("tableHeader:controllerName")}
      </div>
    ),
  },
  {
    id: "id",
    label: t("tableHeader:controllerId"),
    numeric: false,
    clickable: false,
    enableSorting: false,
    size: "150px",
    RowCell: (props) => <SimpleTextCell {...props} />,
    HeaderCell: () => (
      <div
        style={{
          color: "var(--headers)",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {t("tableHeader:controllerId")}
      </div>
    ),
  },
  {
    id: "facilities",
    label: t("tableHeader:facilities"),
    numeric: false,
    clickable: false,
    enableSorting: false,
    size: "150px",
    HeaderCell: () => (
      <div
        style={{
          color: "var(--headers)",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {t("tableHeader:facilities")}
      </div>
    ),
  },
  {
    id: "messageTemplatesSettings",
    label: t("tableHeader:messageTemplatesSettings"),
    numeric: false,
    enableSorting: false,
    size: "210px",
    RowCell: (props) => <ClickableNameCell {...props} />,
    HeaderCell: () => (
      <div
        style={{
          color: "var(--headers)",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {t("button:messageTemplates")}
      </div>
    ),
  },
  {
    id: "status",
    label: t("tableHeader:controllerStatus"),
    numeric: false,
    clickable: false,
    enableSorting: false,
    size: "120px",
    mode: 'edit',
    RowCell: (props) => <ControllerStatusCell {...props} />,
    HeaderCell: () => (
      <div
        style={{
          color: "var(--headers)",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: 'center'
        }}
      >
        {t("tableHeader:controllerStatus")}
      </div>
    ),
  },
  {
    id: "edit",
    numeric: false,
    enableSorting: false,
    label: "",
    size: 50,
    mode: 'edit',
    clickable: false,
    HeaderCell: () => (
      <div
        style={{
          height: '50px',
        }}
      >
      </div>
    ),
  },
];