import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useAddOptionMutation } from "../../../../../middleware/facilitiesApi";
import { useManipulateFilesMutation } from "../../../../../middleware/apiUploadFile";
import { useAction } from "../../../../../hooks/actions";
import CloseIcon from "../../../../components/icons/CloseIcon";
import FormItem from "../../../../components/FormItem";
import {
  addObjectBaseFormConfig,
  addObjectCommonFormConfig,
  addObjectImgFileFormConfig,
  organizationWorkingForConfig,
} from "./form-config";
import { getRequestData, getRequestDataFilesForAdd } from "./helper";
import { useAppSelector } from "../../../../../hooks/redux";
import "./styles.css";

const AddObjectModal = ({ isVisible, handleClose, isNeedRefetchData, clearState }) => {
  const { setIsErrorRequest, setIsSuccessSubmit } = useAction();
  const { role } = useAppSelector((state) => state.users.info);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [manipulateFiles, { error: errorFile, isLoading: isLoadingFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();
  const [addOption, { isError: isErrordeleteEquip, isLoading: isLoadingAdd, isSuccess: isSuccessdeleteEquip }] =
    useAddOptionMutation();

  async function onSubmit(data) {
    const newData = getRequestData(data);

    role !== "Суперадмин" && delete newData.organizationId
    const file = newData.avatar;
    delete newData.avatar;
    addOption(newData).then((responseAdd) => {
      const formData = getRequestDataFilesForAdd(responseAdd?.data.id, "FacilityOption", file);
      manipulateFiles(formData).then(() => {
        clearState();
        isNeedRefetchData();
        handleClose();
      });
    });
  }

  useEffect(() => {
    setIsSuccessSubmit(isSuccessdeleteEquip || isSuccessFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessdeleteEquip, isSuccessFile]);

  useEffect(() => {
    setIsErrorRequest(isErrordeleteEquip || errorFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrordeleteEquip, errorFile]);

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="tariff" width="676px">
        <Box className="tariff-title">
          <Typography>Добавить объект</Typography>
          <Box className="tariff-close-btn" onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <DialogContent sx={{ padding: "16px 0 0px 0", width: "100%" }}>
          <form id="myform" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: "flex", gap: "48px", alignItems: "center" }}>
              <Box>
                {addObjectImgFileFormConfig.map(({ name, label, input, ...rest }) => (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    setValue={setValue}
                    {...rest}
                  />
                ))}
              </Box>
              <Box width={"100%"}>
                {addObjectCommonFormConfig.map(({ name, label, input, ...rest }, i) => (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    {...rest}
                  />
                ))}
              </Box>
            </Box>
            <Box>
              {addObjectBaseFormConfig.map(({ name, label, input, ...rest }, i) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}
            </Box>

            {role === "Суперадмин" && (
              <Box>
                {organizationWorkingForConfig.map(({ name, label, input, ...rest }, i) => (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    {...rest}
                  />
                ))}
              </Box>
            )}
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: "0" }}>
          <Button
            form="myform"
            type="submit"
            disabled={isLoadingFile || isLoadingAdd}
            className="btn btn-primary-fill magazine_events-select_container-btn"
            sx={{ margin: "auto" }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddObjectModal;
