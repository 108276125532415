import { fileSizeCheck, fileTypeCheck } from "../../../../../validation";
import { AddressInput } from "../../../../components/AddressInput";
import InputText from "../../../../components/InputText";
import SelectSimple from "../../../../components/SelectSimple";
import { OrganizationsSelect } from "../../../EmployeeList/form-config";
import { InputObjectImgFileSingle, SelectSimpleGetRegoinsSearch, SelectSimpleGetTariffs } from "./Components";

const formFields = {
  address: "address",
  name: "name",
  category: "category",
  tariff: "tariff",
  regionId: "regionId",
  avatar: "avatar",
  organizationId: "organizationId"
};

export const objectType = [
  { name: "Некоммерческий", value: 0 },
  { name: "Коммерческий", value: 1 },
];

export const addObjectImgFileFormConfig = [
  {
    name: formFields.avatar,
    type: "file",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png"];
          return fileTypeCheck(files, formFields.buildingFacadePhoto, availableFileTypes);
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(files, formFields.buildingFacadePhoto, maxAvailableFileSize);
        },
      },
    },
    input: (props) => <InputObjectImgFileSingle name={formFields.avatar} {...props} />,
  },
];
export const addObjectCommonFormConfig = [
  {
    name: formFields.address,
    label: "label:address",
    placeholder: "placeholder:address",
    shouldSetCoordinates: true,
    rules: { required: true },
    input: (props) => <AddressInput {...props} />,
  },

  {
    name: formFields.name,
    label: "label:organizationName",
    placeholder: "placeholder:name",
    type: "text",
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
];

export const addObjectBaseFormConfig = [
  {
    name: formFields.tariff,
    label: "title:tarif",
    placeholder: "placeholder:tarif",
    type: "text",
    displayEmpty: true,
    rules: { required: true },
    input: (props) => <SelectSimpleGetTariffs {...props} />,
  },
  {
    name: formFields.category,
    label: "label:objectType",
    placeholder: "placeholder:selectObjectType",
    displayEmpty: true,
    options: objectType,
    type: "text",
    rules: { required: true },
    input: (props) => <SelectSimple {...props} />,
  },
  {
    name: formFields.regionId,
    label: "label:region",
    placeholder: "placeholder:selectRegion",
    displayEmpty: true,
    rules: {
      required: true,
    },
    input: (props) => <SelectSimpleGetRegoinsSearch {...props} />,
  },
];

export const organizationWorkingForConfig = [
  {
    id: 1,
    name: formFields.organizationId,
    label: "title_page:organizations",
    type: "select-text",
    displayEmpty: true,
    placeholder: "placeholder:organization",
    rules: {
      // required: true,
    },
    input: (props) => <OrganizationsSelect {...props} />,
  },
];
