import React from "react";
import { t } from "i18next";
import { formFields } from "./form-config";
import { SimpleTextCell } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import { Box } from "@mui/material";
import { ClickableNameCell } from "../MyOrganizations/Components";
import {
  PeriodDateCell,
  SelectSimpleEquipKinds,
  ResponsibleTextCell,
  SelectPlanPeriods,
} from "./Components";
import { SelectSimpleObject } from "../EquipmentList/Components";

// const systemOptions = [{ value: "все", name: "все" }];
export const getColumns = ({
  control,
  columnsFromStorage,
  searchTaskName,
  setsearchTaskName,
  searchresUser,
  setSearchresUser,
  searchresCreator,
  setSearchresCreator,
  setValue
}) =>
  [
    {
      id: formFields.taskName,
      numeric: false,
      enableSorting: false,
      size: "100px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.taskName],
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.TaskName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: '100%'
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:group_task")}
              className="select_in_built small-light"
              searchValue={searchTaskName}
              disabled
              changeSearchValue={setsearchTaskName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.act,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.act],
      HeaderCell: () => (
        <Box sx={{ width: "100%" }}>{t("tableHeader:act")}</Box>
      ),
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:system"),
      size: '200px',
      clickable: true,
      isVisible: columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("tableHeader:system")}
          name={`${formFields.searchParams}.${formFields.EquipmentKindId}`}
          sx={{
            padding: "13px 5px 13px 16px",
            width: '100%'
          }}
          className="select_in_built small-light"
          input={(props) => <SelectSimpleEquipKinds {...props} />}
        />
      ),
    },
    {
      id: formFields.periodDate,
      numeric: false,
      enableSorting: false,
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage["planPeriodName"],
      RowCell: (props) => <PeriodDateCell {...props} />,
      HeaderCell: (props) => (
        <FormItem
          control={control}
          placeholder={t("tableHeader:planPeriodName")}
          name={`${formFields.searchParams}.${formFields.PlanPeriodId}`}
          sx={{
            width: '100%',
            padding: "13px 0 13px 16px",
          }}
          className="select_in_built small-light"
          input={(props) => <SelectPlanPeriods {...props} />}
        />
      ),
    },
    {
      id: formFields.protectionObjectName,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.protectionObjectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.protectionObjectId}`}
          sx={{ padding: "13px 0 13px 16px" }}
          label={t("tableHeader:protectionObjectName")}
          width="100%"
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
              name={`${formFields.searchParams}.${formFields.protectionObjectId}`}
              setValue={setValue}
            />
          )}
        />
      ),
    },
    {
      id: formFields.status,
      numeric: false,
      enableSorting: false,
      size: "130px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage["completionProgress"],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "var(--headers)",
          }}
        >
          {t("tableHeader:task_statistics")}
        </Box>
      ),
    },

    {
      id: formFields.executiveUsers,
      numeric: false,
      enableSorting: false,
      size: "280px",
      clickable: false,
      isVisible: columnsFromStorage["executiveUsers"],
      RowCell: (props) => <ResponsibleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.ExecutiveUserName}`}
          disabled
          sx={{
            padding: "9px 0 9px 16px",
            width: '100%'
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:executiveUsers")}
              className="select_in_built small-light"
              searchValue={searchresUser}
              changeSearchValue={setSearchresUser}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.responsibleCustomerUsers,
      numeric: false,
      enableSorting: false,
      size: "240px",
      clickable: false,
      isVisible: columnsFromStorage["responsibleCustomerUsers"],
      RowCell: (props) => <ResponsibleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          disabled
          name={`${formFields.searchParams}.${formFields.ResponsionCustomerUserName}`}
          sx={{
            width: '100%',
            padding: "9px 0 9px 16px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:responsibleCustomerUsers")}
              className="select_in_built small-light"
              searchValue={searchresCreator}
              changeSearchValue={setSearchresCreator}
              {...props}
            />
          )}
        />
      ),
    },
  ].filter(({ isVisible }) => isVisible);
