import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";
import useScreenSize from "../../../../hooks/useScreenSize";
import { useLazyGetBillingDocumentsQuery } from "../../../../middleware/facilitiesApi";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../../middleware/usersApi";
import TableBasic from "../../../components/TableBasic";
import { getTableHieght } from "../../../../helpers/getTableHieght";
import { dateFormatForSubmit } from "../../../../constants";
import { formFields } from "./form-config";
import { getColumns } from "./columns";

const DocumentsTable = () => {
  const navigate = useNavigate();
  const { billingDocuments } = useAppSelector((state) => state.facilities);
  const { setIsErrorRequest } = useAction();
  const [PageNumber, changePageNumber] = useState(0);
  const [searchObject, setSearchObject] = useState("");
  const [workCompletedAct, setworkCompletedAct] = useState("");
  const [daterange, setDaterange] = useState([null, null]);


  const { control, setValue, getValues } = useForm();
  const screenSize = useScreenSize();
  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "JobList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const [getBillingDocuments, { error, isLoading: isLoadingBillings, isFetching }] =
    useLazyGetBillingDocumentsQuery();

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.orderNumber}`, searchObject);
  }, [searchObject, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.workCompletedActNumber}`, workCompletedAct);
  }, [workCompletedAct, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.paymentDate}`, daterange);
  }, [daterange, setValue]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();
  console.log("facilitiesBillings", params);
  const columns = useMemo(
    () =>
      getColumns({
        control,
        navigate,
        columnsFromStorage,
        searchObject,
        setsearchObject: setSearchObject,
        daterange,
        setdaterange: setDaterange,
        workCompletedAct,
        setworkCompletedAct,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const rows = useMemo(() => {
    const { data } = billingDocuments;
   
    return data?.length
      ? data.map(({ orderNumber, orderFileLink, billingDate, isPaid, workCompletedActLink, workCompletedActNumber }) => {
        return {
          orderNumber: {
            link: orderFileLink,
            value: orderNumber
          },
          isPaid,
          billingDate,
          workCompletedActNumber: {
            link: workCompletedActLink,
            number: workCompletedActNumber
          },
        };
      }
      )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDocuments]);

  const recordsCount = useMemo(() => billingDocuments && billingDocuments?.recordsCount, [billingDocuments]);

  const getIsPaidValue = (isPaid) => {
    if(isPaid !== 1 && isPaid !== 2) return ""
    return isPaid === 2 ? false : true
  }

  useEffect(() => {
    const payDate = getValues(`${formFields.searchParams}.${formFields.paymentDate}`);
    if (params?.paymentDate) delete params.paymentDate;
    let dateFilter = {};
    if (payDate) {
      if (payDate[1] != null) dateFilter["paymentDateMax"] = dayjs(payDate[1]).format(dateFormatForSubmit);
      if (payDate[0] != null) dateFilter["paymentDateMin"] = dayjs(payDate[0]).format(dateFormatForSubmit);
    }

    changePageNumber(0);
    getBillingDocuments({
      ...params,
      ...dateFilter,
      isPaid: getIsPaidValue(params?.isPaid),
      PageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const payDate = getValues(`${formFields.searchParams}.${formFields.paymentDate}`);
    if (params?.paymentDate) delete params.paymentDate;
    let dateFilter = {};
    if (payDate) {
      if (payDate[1] != null) dateFilter["paymentDateMax"] = dayjs(payDate[1]).format(dateFormatForSubmit);
      if (payDate[0] != null) dateFilter["paymentDateMin"] = dayjs(payDate[0]).format(dateFormatForSubmit);
    }

    getBillingDocuments({
      ...params,
      ...dateFilter,
      isPaid: getIsPaidValue(params?.isPaid),
      PageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Box className="template_page">
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => { }}
        withSearchField={false}
        isLoading={isLoadingBillings || isFetching}
        pageNumber={PageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={getTableHieght(screenSize,true).min}
        maxHeight={getTableHieght(screenSize,true).max}
      />
    </Box>
  );
};

export default DocumentsTable;
