import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { Typography, Box, Link } from "@mui/material";
import SelectGrouping from "../../components/SelectGrouping";
import { PATHS } from "../../../constants";
import { useLazyGetAllMagazineEventsQuery } from "../../../middleware/magazineApi";
import {
  useGetActionListQuery,
  useGetEntityListQuery,
  useLazyGetEntityFieldListQuery,
} from "../../../middleware/logsApi";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { formFields } from "./form-config";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import { EmployeeDialog } from "../EmployeeList/EmployeeDialog";

export const JustFullNameCell = ({ item }) => {
  const { fullName } = item || {};

  return <Typography className="table_row_text">{fullName || ""}</Typography>;
};
export const DateFormatCell = ({ item }) => {
  const dateFormat = "DD.MM.YYYY HH:mm";
  const content = dayjs(item).format(dateFormat).split(" ");

  return content.map((e, i) => (
    <Typography key={i} className="table_row_text">
      {e}
    </Typography>
  ));
};
export const ClickableFullNameCell = ({ item }) => {
  const { userId, fullName, position, organization } = item || {};
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };
  return (
    <>
      <Typography className="table-row-cell-link" onClick={() => handleOpenEmployeeDialogState(userId)}>
        {fullName || ""}
      </Typography>
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "14px",
          color: "var(--additionalGrey)",
          fontFamily: "var(--INTER)",
        }}
      >
        {position || ""}
      </Typography>
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "14px",
          color: "var(--additionalGrey)",
          fontFamily: "var(--INTER)",
        }}
      >
        {organization || ""}
      </Typography>
      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}
    </>
  );
};

export const SimpleTextCell = ({ item }) => {
  console.log("item", item);
  return <Typography className="table_row_text">{item || ""}</Typography>;
};

export const ClickableTaskTextCell = ({ item, row, pageNumber, control }) => {
  const { jobId } = row || {};
  const [getAllMagazineEvents] = useLazyGetAllMagazineEventsQuery();
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });
  const [openTask, setOpenTask] = useState(false);

  const handleCloseDialog = () => {
    setOpenTask(true);
  };

  return (
    <>
      <Typography className="table-row-cell-link" onClick={() => setOpenTask(true)}>
        {item}
      </Typography>
      {openTask && (
        <TaskDialog
          open={openTask}
          setOpen={setOpenTask}
          taskId={jobId}
          refetch={getAllMagazineEvents.bind(null, {
            ...params,
            pageNumber,
          })}
        />
      )}
    </>
  );
};

export const ClickableEquipmentTextCell = ({ item, row }) => {
  const navigate = useNavigate();
  const { equipmentId } = row || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={() => equipmentId && navigate(PATHS.GET_EQUIPMENT_DETAILS(equipmentId))}
    >
      {item}
    </Typography>
  );
};
export const ClickableActionNameCell = ({ item }) => {
  const { name, action } = item || {};
  return (
    <Typography
      sx={{
        display: "-webkit-box",
        overflow: "hidden",
        "-webkitLineClamp": "2",
        "-webkitBoxOrient": "vertical",
        textAlign: "center",
        color: "var(--main)",
      }}
      className={name ? "table-row-cell-link" : ""}
      onClick={action ? action : () => {}}
    >
      {name || ""}
    </Typography>
  );
};

export const SelectAction = (props) => {
  const { data, refetch } = useGetActionListQuery();

  return <SelectSimpleInbuilt options={data || []} {...props} />;
};
export const SelectEntity = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetEntityListQuery(searchValue);

  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch]);

  const options = useMemo(() => {
    return data
      ? [...data] //getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SelectSimpleSearch
      {...props}
      displayEmpty={true}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      withClosedIcon={true}
      setValue={props.setValue}
    />
  );
};

export const SelectEntityField = (props) => {
  const { control } = props;

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [getEntityFields] = useLazyGetEntityFieldListQuery();

  useEffect(() => {
    if (params?.EntityTypeId) {
      getEntityFields({ entityTypeId: params?.EntityTypeId || "", name: searchValue }).then(({ data }) => {
        setData(data);
      });
    }
  }, [searchValue, params?.EntityTypeId]);

  const options = useMemo(() => {
    return data
      ? [...data] //getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SelectSimpleSearch
      {...props}
      displayEmpty={true}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
    />
  );
};

export const KeyFieldsList = ({ item: data }) => {
  const { fields, action } = data;

  

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      {fields?.length > 0 &&
        fields?.map((item) => {
          const {field, linkType, value, displayValue, hideValue} = item
          const getDisplayValue = !hideValue ? displayValue : false
          const getCountOfVisibleElements = Object.values([value, field, getDisplayValue]).filter(Boolean)
          const isOnlyOneValue = getCountOfVisibleElements.length === 1
          return (
            <>
              {(value || displayValue) && (
                <Box
                  display="flex"
                  gap="15px"
                  justifyContent="center"
                  alignItems="center"
                  border={!isOnlyOneValue ? "1px solid #CFD9E8" : "none"}
                  minHeight={"56px"}
                  width="100%"
                >
                  {field && (
                    <>
                      <Typography textAlign={"center"} sx={{ padding: "5px 15px" }}>
                        {field}
                      </Typography>
                      <Box
                        sx={{
                          width: "1px",
                          minHeight: "56px",
                          backgroundColor: "#CFD9E8",
                          height: "100%",
                          maxHeight: "300px",
                        }}
                      ></Box>
                    </>
                  )}

                  {!hideValue && value && (
                    <Box sx={{ width: "200px" }}>
                      <Typography textAlign={"center"}>{value}</Typography>
                    </Box>
                  )}

                  {!hideValue && displayValue && <Box sx={{ width: "1px", minHeight: "56px", backgroundColor: "#CFD9E8" }}></Box>}

                  {displayValue && (
                    <Box sx={{ display: "flex", width: "250px", textAlign: "center", justifyContent: "center" }}>
                      <Typography
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          "-webkitLineClamp": "2",
                          "-webkitBoxOrient": "vertical",
                          textAlign: "center",
                        }}
                        className={linkType ? "table-row-cell-link" : ""}
                        onClick={() => action(value, linkType)}
                      >
                        {displayValue}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </>
          );
        })}
    </Box>
  );
};
export const ChangeFieldsList = ({ item }) => {
  return (
    <Box>
      {item?.length > 0 &&
        item?.map(({ displayValue, field, originalDisplayValue, originalValue, value }, index) => (
          <Box display="flex" gap="10px">
            <Typography>{`${index + 1}. ` || ""}</Typography>
            <Typography>{displayValue || ""}</Typography>
            <Typography>{field || ""}</Typography>
            <Typography>{originalDisplayValue || ""}</Typography>
            <Typography>{originalValue || ""}</Typography>
            <Typography>{value || ""}</Typography>
          </Box>
        ))}
    </Box>
  );
};
