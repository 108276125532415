import { fireCompartmentShortSections } from "./form-config";
import Box from "@mui/material/Box";
import InputBlockField from "../../components/InputBlockField";
import { formFields } from "./form-config";
import FormItemCheckboxControlled from "../../components/FormItem/FormItemCheckboxControlled";
import FormItem from "../../components/FormItem";
import React from "react";

export const FireCompartmentShortForm = ({
  control,
  errors,
  onSubmit,
  unregister,
  getValues,
  setValue,
  register,
}) => {

  return (
    <form
      id="myform"
      className="fire-compartment-form scrolled-form"
      onSubmit={onSubmit}
    >
      {fireCompartmentShortSections?.map(
        ({ id, name: sectionName, items }) => (
          <Box
            key={id}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              // width: "97%",
            }}
          >
            {items.map(
              ({
                id,
                name,
                label,
                input,
                expended,
                fieldArrayItem,
                CustomFormItem,
                block,
                title,
                ...rest
              }) =>
                CustomFormItem ? (
                  <CustomFormItem
                    key={
                      sectionName
                        ? `${id}.${sectionName}.${name}`
                        : `${id}.${name}`
                    }
                    control={control}
                    errors={errors}
                    name={sectionName ? `${sectionName}.${name}` : name}
                    setValue={setValue}
                    getValues={getValues}
                    {...rest}
                  />
                ) : block ? (
                  <InputBlockField
                    key={sectionName ? `${sectionName}.${name}` : name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    expended={expended}
                    {...rest}
                    title={title}
                    sectionName={sectionName}
                    formFields={formFields}
                    {...rest}
                  />
                ) : expended ? (
                  <FormItemCheckboxControlled
                    key={sectionName ? `${sectionName}.${name}` : name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    expended={expended}
                    {...rest}
                  />
                ) : (
                  <FormItem
                    key={sectionName ? `${sectionName}.${name}` : name}
                    control={control}
                    errors={errors}
                    name={sectionName ? `${sectionName}.${name}` : name}
                    label={label}
                    input={input}
                    {...rest}
                  />
                )
            )}
          </Box>
        )
      )}
    </form>
  );
};
