import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Stage,
  Layer,
  Line,
  Circle,
  Group,
  Image,
  Rect,
  Text,
  Transformer
} from "react-konva";

import { Box } from "@mui/material";

import {
  loadImg,
  getRectCoordinates,
  getIsInsidePoint,
  getReplacecoord
} from "../../../helpers/helper";
import { getParamsTextWithScale } from "../../pages/Plume/ImageSchema/helpers";
import { formFields, modalAddressConfig } from "../../pages/Plume/form-config";
import { SubNotificationPanel } from "../../pages/Plume/ImageSchema/SubNotificationPanel";
import { ModalRoomAddressEdit } from "../Modals/ModalRoomAddressEdit";
import { useAppSelector } from "../../../hooks/redux";

const emptyRectParams = {
  x: null,
  y: null,
  width: null,
  height: null,
};

const initialTransform = {
  x: 0,
  y: 0,
  active: false,
};

export const EditDrawingPlume = ({
  width,
  height,
  control,
  setValue,
  schemaImg,
  isDrawPoligon,
  isEditShape,
  isReplaceShape,
  setisReplaceShape,
  roomAddresses,
  setsearchIntNum,
  searchIntNum,
  facilityId,
  getValues,
  resetField,
  transformCanvasData,
  setTransformCanvasData,
  newRoomAddresses,
  scaleAddEquip,
  canvasRef,
  imageRef,
  setscaleAddEquip,
  setoffsetAddEquip,
  widthWorkplace,
  heightWorkplace,
  offsetAddEquip,
  handleDeleteRoom,
  minScale,
  isTurnDrag,
  selectShape,
  selectedId,
  scaleRoom,
  scaleDrag,
  isAddEquip = false,
  xyGroup,
  setxyGroup,
  setNewRoomAddresses,
}) => {

  const [image, setImage] = useState(null);
  const [transform, setTransform] = useState(initialTransform);
  const stageRef = useRef(null);
  const [selectedname, Setselectedname] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [selectedplume, Setselectedplume] = useState(null);
  const [selectedintNum, SetselectedintNum] = useState(null);
  const [transformerVisible, setTransformerVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [Editopen, setEditopen] = useState(false);
  const [points, setPoints] = useState([]);
  const scrollRef = useRef(null);
  const [rectParams, setRectParams] = useState(emptyRectParams);
  const { SCHEMA_CANVAS1, SCHEMA_CANVAS } = useAppSelector((state) => state.settings);

  const SCCHEAME_CANVAS = isAddEquip ? SCHEMA_CANVAS1 : SCHEMA_CANVAS
  const shapeRef = React.useRef();

  const trRef = React.useRef();
  const scale = useMemo(
    () => ({
      x: SCCHEAME_CANVAS.WIDTH / width,
      y: SCCHEAME_CANVAS.HEIGHT / height,
    }),
    [width, height, SCCHEAME_CANVAS]
  );
  const screenRelatedNewRoomAddresses = useMemo(() => {
    return newRoomAddresses.map(({ coordinates, ...rest }) => ({
      ...rest,
      coordinates: coordinates.map((item, index) =>
        index % 2 ? item / scale.y : item / scale.x
      ),
    }));
  }, [scale, newRoomAddresses]);

  const style = {
    display: transform.active ? "flex" : "none",
    left: `0`,
    top: `-68px`,
    borderRadius: '32px',
  };

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
  }, [schemaImg, height, width]);

  const allRoomAddresses = useMemo(() => {

    return [
      ...roomAddresses?.filter(({ id }) => id),
      ...screenRelatedNewRoomAddresses,
    ];
  }, [roomAddresses, screenRelatedNewRoomAddresses]);
  console.log("allRoomAddresses", allRoomAddresses)
  const drawScheme = async (schemaImg, height, width) => {
    await loadImg(schemaImg, height, width).then((image) => setImage(image));
  };
  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
  }, [schemaImg, height, width]);

  const getPointPosition = (event) => {
    const { layerX, layerY, changedTouches } = event.evt;
    if (changedTouches) {
      const stage = event.target.getStage().attrs.container;
      const { left, top } = stage.getBoundingClientRect();
      return {
        layerX: changedTouches[0]?.clientX - left,
        layerY: changedTouches[0]?.clientY - top,
      };
    } else {
      return { layerX, layerY };
    }
  };

  const handledbl = (index, nname, plumes, protectionObjectIntegrNumberAltId) => (e) => {
    if (isEditShape) {
      selectShape(index)
      Setselectedname(nname)
      Setselectedplume(plumes)
      setValue(formFields.plumes, plumes.find(el => typeof el === 'string') ? plumes.map(el => ({
        name: el
      })) : plumes)
      SetselectedintNum(protectionObjectIntegrNumberAltId)
      setEditopen(true);

    }

  }
  const handleModalEditCancel = (e) => {
    resetField(formFields.roomAddress);
    setEditopen(false);
  };
  const handleEdit = () => (e) => {
    let arr = []
    let arrnewRoom = []
    let isNew = false

    const plumes = getValues(formFields.plumes)
    getValues(formFields.roomAddresses).map((item, index) => {

      if (index == selectedId) {
        isNew = true
        arr.push({ id: item.id, name: selectedname, plumes, protectionObjectIntegrNumberAltId: selectedintNum, coordinates: item.coordinates })
      } else {
        arr.push(item)
      }
    })

    if (!isNew)
      newRoomAddresses.map((item, index) => {
        if (getValues(formFields.roomAddresses)?.length + index == selectedId) {
          arrnewRoom.push({ name: selectedname, plumes, protectionObjectIntegrNumberAltId: selectedintNum, coordinates: item.coordinates })
        } else {
          arrnewRoom.push(item)
        }
      });

    isNew ? setValue(formFields.roomAddresses, arr) : setNewRoomAddresses(arrnewRoom)
    setEditopen(false);
    setValue(formFields.plumes, [])
  };
  React.useEffect(() => {
    if (selectedId != null && isEditShape) {

      // we need to attach transformer manually


      trRef?.current?.nodes([canvasRef.current.children[selectedId + 1]]);
      //trRef?.current?.getLayer().batchDraw();
    }
  }, [selectedId]);



  const findValueById = (dataArray, id, coor) => {
    const foundObject = dataArray?.find(item => item.id === id);
    return foundObject ? coor == "x" ? foundObject.x : foundObject.y : 0; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const handleImageClick = () => {
    if (isReplaceShape) return
    setTransformerVisible(false)
    selectShape(null)
  };
  const handleGroupClick = (index) => (e) => {
    if (!isEditShape) return
    setTransformerVisible(true);
    selectShape(index)
  };
  const findscaleById = (id) => {
    let scale = 1;
    const foundObject = xyGroup?.find(item => item.id === id);
    if (foundObject) {
      scale = foundObject.scaleX
    }
    return scale; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const handleWheel = (e) => {

    e.evt.preventDefault();
    const stage = stageRef.current;
    const canvas = canvasRef.current;
    let newScale = scaleAddEquip;
    if (e.evt.deltaY < 0) {
      newScale = scaleAddEquip <= minScale + 5 ? scaleAddEquip + 0.5 : scaleAddEquip; // Ограничение максимального зума (2 в данном случае)
    } else {
      newScale = scaleAddEquip > 0.5 ? scaleAddEquip - 0.5 : scaleAddEquip; // Ограничение минимального зума (0.5 в данном случае)
    }
    setscaleAddEquip((prev) => newScale);

    const pointTo = {
      x: stage.getPointerPosition().x / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: stage.getPointerPosition().y / scaleAddEquip - canvas.y() / scaleAddEquip,
    };
    //setoffsetAddEquip((prev) => stageposition)
    setoffsetAddEquip({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });

  };
  const handleScrollbarMouseUp = (e, typeScroll) => {
    if (typeScroll == "vertical") {
      setoffsetAddEquip({ ...offsetAddEquip, y: -e.target.y() * scaleAddEquip });
    } else {
      setoffsetAddEquip({ ...offsetAddEquip, x: -e.target.x() * scaleAddEquip });
    }
    /* const canvas = canvasRef.current;
 
     const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (widthWorkplace / scale - canvas.x() / scale), (heightWorkplace / scale - canvas.y() / scale)];
     groupClick(coordinates, null, scalecanvas, image.height, image.width, false, true);
     */
  };
  const handleScrollbarMouseMove = (e, typeScroll) => {

    const canvas = canvasRef.current
    if (typeScroll == "vertical") {
      canvas.position({ x: canvas.x(), y: -e.target.y() * scaleAddEquip })
    } else {
      canvas.position({ y: canvas.y(), x: -e.target.x() * scaleAddEquip })
    }
  };
  const handleDragEndStage = (e) => {

    const stage = canvasRef.current;

    const coordinates = [-stage.x() / scaleAddEquip, -stage.y() / scaleAddEquip, (width - stage.x()) / scaleAddEquip, (height - stage.y()) / scaleAddEquip];
    setoffsetAddEquip({ x: stage.x(), y: stage.y() })
    //  scrollRef.current.scrollTop = stage.y()
    //scrollRef.current.scrollLeft  = stage.x()
  }

  const handleDragMove = (e) => {
    if (isEditShape) return
    const newX = e.target.x();
    const newY = e.target.y();

    // console.log("clampedX",e.target.y(),e.target.x())
    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(image.width * scaleAddEquip - width);

    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(image.height * scaleAddEquip - height);

    // Если новые координаты выходят за границы, оставляем их на границе
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);

    setTransformCanvasData({
      x: clampedX,
      y: clampedY,
    });

  }
  const handleShapeMouseMove = (e, text, coordinates) => {

    const mousePos = e.target.getStage().getPointerPosition();
    setTooltipText(text);
    setTooltipPosition({ x: mousePos.x + 5, y: mousePos.y + 5 }); // Обновляем позицию tooltip
    setTooltipVisible(true);
  };

  const handleShapeMouseOut = () => {
    setTooltipVisible(false);
  };

  const toStartDraw = (event) => {
    if (!isReplaceShape) return
    if (isTurnDrag) return;
    if (isFinished) return;
    const { layerX, layerY } = getPointPosition(event);

    const isInside = getIsInsidePoint({
      x: layerX,
      y: layerY,
      roomAddresses: allRoomAddresses || [],
    });

    //  if (isInside) return;

    if (isDrawPoligon) {
      const distanceX = Math.abs(
        layerX / scaleAddEquip - offsetAddEquip.x / scaleAddEquip - points[0]?.x
      );
      const distanceY = Math.abs(
        layerY / scaleAddEquip - offsetAddEquip.y / scaleAddEquip - points[0]?.y
      );

      const insideRadius = distanceX <= 1 && distanceY <= 1;
      let insideX = false
      let insideY = false

      if (insideRadius) {
        insideX = true
        insideY = true
      }

      const newPoint = {
        x: insideX
          ? points[0]?.x
          : layerX / scaleAddEquip - offsetAddEquip.x / scaleAddEquip,
        y: insideY
          ? points[0]?.y
          : layerY / scaleAddEquip - offsetAddEquip.y / scaleAddEquip,
      };
      console.log("isInside", newPoint.x, newPoint.y);
      if (insideX && insideY) {
        setIsFinished(true);
        setTransform({
          x: points[0]?.x,
          y: points[0]?.y,
          active: true,
        });
      }

      setPoints([...points, newPoint]);
    } else {
      const newPoint = {
        x: layerX / scaleAddEquip - offsetAddEquip.x / scaleAddEquip,
        y: layerY / scaleAddEquip - offsetAddEquip.y / scaleAddEquip,
      };
      const { x, y } = rectParams || {};

      if (x === null || y === null) {
        setIsFinished(false);
        setRectParams({ ...newPoint });
      }
    }
  };
  console.log("points", points);
  const toMoveDraw = (event) => {
    if (!isFinished) {
      const { layerX, layerY } = getPointPosition(event);
      const { x, y } = rectParams || {};
      if (x && y) {
        const width =
          layerX / scaleAddEquip - offsetAddEquip.x / scaleAddEquip - x;
        const height =
          layerY / scaleAddEquip - offsetAddEquip.y / scaleAddEquip - y;
        const isInside = getIsInsidePoint({
          x: layerX,
          y: layerY,
          roomAddresses: allRoomAddresses || [],
        });

        //  if (isInside) return;
        setRectParams({ ...rectParams, width, height });
      }
    }
  };

  const toEndDraw = (event) => {
    if (isDrawPoligon || isFinished) return;
    const { layerX, layerY } = getPointPosition(event);
    const { width: widthRect, height: heightRect } = rectParams || {};

    const isToGoUotFieldCanvas =
      layerY <= 0 ||
      layerY >= heightWorkplace - 20 ||
      layerX <= 0 ||
      layerX >= widthWorkplace - 20;
    console.log("sizes", layerY, layerX, isToGoUotFieldCanvas, height, width);
    if (widthRect && heightRect && !isToGoUotFieldCanvas) {
      const newPoint = { x: layerX, y: layerY };
      setIsFinished(true);
      setTransform({
        x: newPoint.x - widthRect,
        y: newPoint.y + 2,
        active: true,
      });
    } else {
      setRectParams(emptyRectParams);
    }
  };
  const handleCloseIcon = () => {
    setTransform(initialTransform);
    setPoints([]);
    setRectParams(emptyRectParams);
    setIsFinished(false);
    selectShape(null)
    setisReplaceShape(false)
  };

  const handleCheckIcon = () => {

    let arr = []
    const newCoordinates = isDrawPoligon
      ? points?.map((e) => [e.x * scale.x, e.y * scale.y]).flat()
      : getRectCoordinates(rectParams, scale);

    getValues(formFields.roomAddresses).map((item, index) => {

      if (index == selectedId) {
        arr.push({ ...item, coordinates: getReplacecoord(newCoordinates) })
      } else {
        arr.push(item)
      }
    })

    setValue(formFields.roomAddresses, arr)
    isDrawPoligon ? setPoints([]) : setRectParams(emptyRectParams);
    selectShape(null)
    setisReplaceShape(false)
    setTransform(initialTransform);
    setIsFinished(false);
  };
  const [selectedPointIndex, setSelectedPointIndex] = useState(null);
  const [selectedPoints, setselectedPoints] = useState(null);

 /* const handleDragMovePoint = (e,coordinates, index) => {
    const newPoints = coordinates;
    newPoints[index * 2] = newPoints[index * 2]+e.target.x()/scaleRoom; // Обновляем X-координату
    newPoints[index * 2 + 1] = newPoints[index * 2 + 1]+e.target.y()/scaleRoom; // Обновляем Y-координату
    //setselectedPoints(newPoints); // Сохраняем обновленные координаты
    let arr = []
    getValues(formFields.roomAddresses).map((item, index) => {
      if (index == selectedId) {
        arr.push({ ...item, coordinates: getReplacecoord(newPoints) })
      } else {
        arr.push(item)
      }
    })

    setValue(formFields.roomAddresses, arr)
  };*/

  // const handlePointClick = (index) => {
  //   setSelectedPointIndex(index);
  // };

  return (
    <Box ref={scrollRef} sx={{ position: "relative", border: "1px solid #CFD9E8", borderRadius: "16px", padding: "10px", width: widthWorkplace ? widthWorkplace - 20 : width - 20, height: heightWorkplace ? heightWorkplace - 20 : height - 20, cursor: isTurnDrag ? "grab" : "crosshair" }}>{/*,overflow: 'auto'}}    onScroll={handleScroll} >*/}
      {/* <SubNotificationPanel
        style={style}
        handleCloseIcon={handleCloseIcon}
        handleCheckIcon={handleCheckIcon}
      /> */}
      <ModalRoomAddressEdit
        name={formFields.roomAddress}
        control={control}
        open={Editopen}
        facilityId={facilityId}
        handleEdit={handleEdit}
        selectedname={selectedname}
        Setselectedname={Setselectedname}
        selectedintNum={selectedintNum}
        SetselectedintNum={SetselectedintNum}
        searchIntNum={searchIntNum}
        setsearchIntNum={setsearchIntNum}
        selectedplume={selectedplume}
        Setselectedplume={Setselectedplume}
        config={modalAddressConfig}
        handleCancel={handleModalEditCancel}
      />
      <Stage
        width={widthWorkplace ? widthWorkplace - 20 : width - 20}
        height={heightWorkplace ? heightWorkplace - 20 : height - 20}

        ref={stageRef}

      >
        <Layer ref={canvasRef}
          scaleX={scaleAddEquip}
          scaleY={scaleAddEquip}
          x={offsetAddEquip.x}
          draggable={isEditShape ? false : true}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEndStage}
          y={offsetAddEquip.y}
          onMouseDown={toStartDraw}
          onMouseUp={toEndDraw}
          onMouseMove={toMoveDraw}
          onTouchStart={toStartDraw}
          onTouchEnd={toEndDraw}
          onTouchMove={toMoveDraw}
          onMouseEnter={e => {
            if (isEditShape) {
              const container = e.target.getStage().container();
              container.style.cursor = 'crosshair';
            } else {
              const container = e.target.getStage().container();
              container.style.cursor = 'grab';
            }
          }}

          onWheel={handleWheel}
          dragBoundFunc={pos => {
            //   console.log("pos",pos)
            const widthPos = widthWorkplace ? widthWorkplace : width
            const HeightPos = heightWorkplace ? heightWorkplace : height
            const offsetX = widthPos - image.width * scaleAddEquip
            const offsetY = HeightPos - image.height * scaleAddEquip
            //  console.log("stage",pos,image.width*scale,offsetX)

            if (offsetX <= 0) {
              if (pos.x <= offsetX) pos.x = offsetX;
              if (pos.x > 0) pos.x = 0;

            } else {
              if (pos.x > offsetX) pos.x = offsetX;
              if (pos.x <= 0) pos.x = 0;
            }

            if (offsetY <= 0) {
              if (pos.y <= offsetY) pos.y = offsetY;
              if (pos.y > 0) pos.y = 0;

            } else {
              if (pos.y > offsetY) pos.y = offsetY;
              if (pos.y <= 0) pos.y = 0;
            }
            return pos;
          }}>
          <Image ref={imageRef} onClick={handleImageClick} image={image} x={0} y={0} />
          <React.Fragment>
            {allRoomAddresses.map(
              ({ id, coordinates, name, plumes, protectionObjectIntegrNumberAltId }, index) =>

                (!!coordinates?.length && (selectedId != index || (selectedId == index && !isReplaceShape))) && (

                  <Group
                    draggable={isEditShape ? true : false}
                    onDblClick={handledbl(index, name, plumes, protectionObjectIntegrNumberAltId)}
                    onClick={handleGroupClick(index)}
                    onMouseMove={(e) => handleShapeMouseMove(e, `${name}`, coordinates)}
                    onMouseOut={handleShapeMouseOut}
                    key={id || index}
                    onTransformEnd={(e) => {
                      const node = e.currentTarget;
                      const scaleX = node.scaleX();
                      const scaleY = node.scaleY();
                      const rotation = node.rotation();
                      const width = node.width();
                      const height = node.height();
                      const x = node.x();
                      const y = node.y();
                      console.log("rotation:rotation,", rotation)
                      let arr = xyGroup || []
                      let i = 0
                      let isOld = false
                      arr?.map((item) => {
                        if (item.id == id) {
                          arr[i] = { ...arr[i], x: x, y: y, scaleX: scaleX, rotation: rotation, scaleY: scaleY, scaleRoom: scaleRoom }
                          isOld = true
                        }

                        i++
                      })
                      if (!isOld)
                        arr.push({ id: id, x: x, y: y, scaleX: scaleX, rotation: rotation, scaleY: scaleY, scaleRoom: scaleRoom })

                      setxyGroup(arr)
                    }}
                    /* onTransformEnd={(e) => {
 
                       //  const line = shapeRef.current.children[selectedId + 1].current.findOne('.line')
                       // console.log("arr",line.points())
                       const points = e.target.children[0].points();
                       const scaleX = e.target.scaleX();
                       const scaleY = e.target.scaleY();
                       const x = e.target.x()
                       const y = e.target.y()
                      
                      // node.scaleX(1);
                      // node.scaleY(1);
 
                          let arr = xyGroup || []
                          let i =0
                          let isOld = false
                          arr?.map((item)=>{
                            if (item.id == id){
                              arr[i] = {...arr[i],x:x,y:y,scaleX:scaleX,scaleY:scaleY}
                              isOld = true
                            }
                              
                            i++
                          })
                          if(!isOld)
                              arr.push({id:id,x:x,y:y,scaleX:scaleX,scaleY:scaleY})
                          console.log("arr",arr)
                          setxyGroup(arr)
                       
                     }}*/
                    onDragEnd={(e) => {
                      let arr = xyGroup || []
                      let i = 0
                      let isOld = false
                      arr?.map((item) => {
                        if (item.id == id) {
                          arr[i] = { ...arr[i], x: e.target.x(), y: e.target.y(), scaleRoom: scaleRoom }
                          isOld = true
                        }

                        i++
                      })
                      if (!isOld)
                        arr.push({ id: id, x: e.target.x(), y: e.target.y(), scaleX: 1, rotation: 0, scaleY: 1, scaleRoom: scaleRoom })

                      setxyGroup(arr)
                    }}

                    onMouseEnter={e => {
                      if (isEditShape) {
                        const container = e.target.getStage().container();
                        container.style.cursor = 'pointer';
                      } else {
                        const container = e.target.getStage().container();
                        container.style.cursor = 'grab';
                      }

                    }}
                    onMouseLeave={e => {
                      // on mouse leave
                      if (isEditShape) {
                        const container = e.target.getStage().container();
                        container.style.cursor = 'crosshair';
                      } else {
                        const container = e.target.getStage().container();
                        container.style.cursor = 'grab';
                      }
                    }}
                    x={findValueById(xyGroup, id, "x")}
                    y={findValueById(xyGroup, id, "y")}
                  >
                    <Line
                      points={coordinates}
                      strokeScaleEnabled={false}
                      stroke={selectedId == index ? "#1876D2" : "#4D5C6E"}
                      fill={selectedId == index ? "rgba(24,118,210,0.3)" : "rgba(110,119,129,0.3)"}
                      closed={true}
                      strokeWidth={3 / scaleAddEquip}
                    />
                    {/*selectedId == index && coordinates.map((point, index) => (
                      <Circle
                        key={index}
                        x={coordinates[index * 2]} // X-координата точки
                        y={coordinates[index * 2 + 1]} // Y-координата точки
                        radius={1}
                        fill={selectedPointIndex === index ? 'red' : 'green'}
                        draggable
                        onDragMove={(e) => handleDragMovePoint(e,coordinates, index)}
                        onClick={() => handlePointClick(index)}
                      />
                    ))}
                    {/* <Text
                      {...getParamsTextWithScale({ coordinates, text: name, line: 0, scale: findscaleById(id) })}
                    />
                    <Text
                      {...getParamsTextWithScale({ coordinates, text: plumes[0]?.name || plumes[0], line: 1, scale: findscaleById(id) })}
                    /> */}
                  </Group>

                )
            )}
            {isDrawPoligon ? (
              <Line
                points={points?.map((e) => [e.x, e.y]).flat()}
                stroke="#1876D2"
                strokeWidth={3 / scaleAddEquip}
                fill="rgba(24,118,210,0.3)"
                closed={isFinished}
              />
            ) : (
              <Rect
                {...rectParams}
                fill={"rgba(24,118,210,0.3)"}
                stroke={"#1876D2"}
                strokeWidth={3 / scaleAddEquip}
              />
            )}
            {transformerVisible && <Transformer
              ref={trRef}
              ignoreStroke
              flipEnabled={false}
              rotateEnabled={true}
              resizeEnabled={true}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
            }
          </React.Fragment>
          <Group>
            {points?.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={3 / scaleAddEquip}
                fill="red"
              />
            ))}
            {isDrawPoligon ? (
              <Line
                points={points?.map((e) => [e.x, e.y]).flat()}
                stroke="#1876D2"
                strokeWidth={3 / scaleAddEquip}
                fill="rgba(24,118,210,0.3)"
                closed={isFinished}
              />
            ) : (
              <Rect
                {...rectParams}
                fill={"rgba(24,118,210,0.3)"}
                stroke={"#1876D2"}
                strokeWidth={3 / scaleAddEquip}
              />
            )}
          </Group>
        </Layer>
        <Layer>
          <Group>
            {image?.height * scaleAddEquip > heightWorkplace &&
              <Rect
                width={25}
                height={heightWorkplace - (image.height * scaleAddEquip - heightWorkplace) / scaleAddEquip}
                fill="#1876D2"
                opacity={0.8}
                x={widthWorkplace - 15 - 20}
                y={-(transformCanvasData.y) / scaleAddEquip}
                draggable
                dragBoundFunc={(pos) => {
                  pos.x = widthWorkplace - 15 - 20;
                  const offsetY = heightWorkplace - (image.height * scaleAddEquip - heightWorkplace) / scaleAddEquip
                  if (pos.y + offsetY > heightWorkplace) pos.y = heightWorkplace - offsetY - 5
                  if (pos.y < 5) pos.y = 5
                  return pos;
                }}
                onMouseEnter={e => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'pointer';

                }}
                onMouseLeave={e => {

                  const container = e.target.getStage().container();
                  container.style.cursor = 'grab';

                }}
                onDragMove={(e) => {
                  handleScrollbarMouseMove(e, "vertical")
                }}
                onDragEnd={(e) => { handleScrollbarMouseUp(e, "vertical") }}
              />
            }
            {image?.width * scaleAddEquip > widthWorkplace &&
              <Rect
                width={widthWorkplace - (image.width * scaleAddEquip - widthWorkplace) / scaleAddEquip}
                height={25}
                fill="#1876D2"
                opacity={0.8}
                y={heightWorkplace - 20 - 15}
                x={-(transformCanvasData.x) / scaleAddEquip}
                draggable
                dragBoundFunc={(pos) => {
                  pos.y = heightWorkplace - 20 - 15;
                  const offsetX = widthWorkplace - (image.width * scaleAddEquip - widthWorkplace) / scaleAddEquip
                  if (pos.x + offsetX > widthWorkplace) pos.x = widthWorkplace - offsetX - 5
                  if (pos.x < 5) pos.x = 5
                  return pos;
                }}
                onMouseEnter={e => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'pointer';

                }}
                onMouseLeave={e => {

                  const container = e.target.getStage().container();
                  container.style.cursor = 'grab';

                }}
                onDragMove={(e) => {
                  handleScrollbarMouseMove(e, "horizontal")
                }}
                onDragEnd={(e) => { handleScrollbarMouseUp(e, "horizontal") }}
              />
            }
          </Group>

        </Layer>
        <Layer>
          {tooltipVisible && (
            <Text
              text={tooltipText}
              fontSize={14}
              padding={10}
              textFill="white"
              fill="black"
              fontStyle="bold"
              alpha={1}
              visible={tooltipVisible}
              x={tooltipPosition.x} // Устанавливаем позицию по x
              y={tooltipPosition.y} // Устанавливаем позицию по y
            />
          )}

        </Layer>
      </Stage>
    </Box>
  );
};
