import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetFacFireCompSelectElementsQuery } from "../../../../middleware/facilitiesApi";
import { useWatch } from "react-hook-form";
import SelectGrouping from "../../../components/SelectGrouping";
import SelectGroupingSystemPPZ from "../../../components/SelectGroupingSystemPPZ";

import {
  useLazyGetEquipmentSelectElementsQuery,
  useGetEquipmentKindSelectElementsQuery,
  useGetEquipmentNamesQuery,
  useLazyGetLastNumberandPrefixQuery,
  useValidateEquipmentNumbersMutation,
} from "../../../../middleware/equipmentsApi";
import {
  getEquipmentTypeIdOptionsGroup,
  getEquipmentTypeIdOptionsGroupAddEquip,
  getFacilityAndFireCompartmentOptionsGroup,
} from "./helpers";
import { dateFormat, dateFormatForSubmit } from "../../../../constants";
import { useGetContractFilesSelectQuery } from "../../../../middleware/apiUploadFile";
import SelectSimple from "../../../components/SelectSimple";
import { useGetResponsibleOrganizationsQuery } from "../../../../middleware/apiOrganizations";
import PdfIcon from "../../../components/icons/PdfIcon";
import dayjs from "dayjs";
import WorkContractFileView from "../../Equipments/WorkContract/WorkContractFileView";
import { useGetExecutiveSelectElementsQuery } from "../../../../middleware/executiveApi";
import { formFields } from "./form-config";
import InputText from "../../../components/InputText";
import { useAction } from "../../../../hooks/actions";
import { InputLabel } from "@mui/material";

export const FacilityAndFireCompartmentSelectGrouping = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: facFireCompSelect = [] } =
    useGetFacFireCompSelectElementsQuery(searchValue);
  const options = getFacilityAndFireCompartmentOptionsGroup(facFireCompSelect);
  if (props?.objectId) {
    const foundObject = options.find((obj) => obj.id === props?.objectId);
    const foundValue = foundObject
      ? foundObject.items.find((obj) => obj.id === props?.getComFireIdByFloor)
      : null;
    props = {
      ...props,
      value: foundValue
        ? JSON.stringify({ name: foundObject.name, id: foundValue.id })
        : "{}",
    };
  }
  return (
    <Box className="medium_container">
      <SelectGrouping
        {...props}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        options={options}
        renderValue={(selected) => {
          //console.log("selectedEquip",selected)
          return selected ? `${JSON.parse(selected)?.name}` : "";
        }}
      />
    </Box>
  );
};

export const PrefixStartNumberInput = ({
  getValues,
  setValue,
  control,
  objectId,
  ...props
}) => {
  let errorType = "";
  if (props.errorTyp?.root) {
    if (props.errorTyp?.root.serverError.typeMulti === 461) {
      errorType = props.errorTyp?.root.serverError.messageMulti;
    }
  }
  const [getLastNumber] = useLazyGetLastNumberandPrefixQuery();
  const [
    validateEquipmentNumbers,
    { error: validateError, data: isSuccessValidate },
  ] = useValidateEquipmentNumbersMutation();
  const { equipmentsCount } = useWatch({
    control,
  });

  const onChangeNumberInput = (event) => {
    var startNumber = event.target.value;
    setValue(formFields.startNumber, event.target.value);
    if (
      startNumber !== "" &&
      equipmentsCount !== "" &&
      equipmentsCount !== null
    ) {
      validateEquipmentNumbers({
        number: startNumber,
        EquipmentsCount: equipmentsCount,
        ProtectionObjectId: objectId,
      }).then((data) => {
        if (data?.error) {
          errorType = data.error.data.Message;
          return;
        }
        getLastNumber({
          startNumber: startNumber,
          count: equipmentsCount,
        }).then((data) => {
          setValue(formFields.endNumber, data.data?.lastNumber);
        });
      });
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <InputText
        {...props}
        onChange={onChangeNumberInput}
        sx={{ width: "350px", borderColor: "red" }}
      />
      <Typography
        sx={{ color: "red", alignSelf: "center", marginLeft: "20px" }}
      >
        {errorType}
      </Typography>
    </Box>
  );
};
export const CountEquipInput = ({
  getValues,
  setValue,
  objectId,
  ...props
}) => {
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [getLastNumber] = useLazyGetLastNumberandPrefixQuery();
  const [
    validateEquipmentNumbers,
    { error: validateError, data: isSuccessValidate },
  ] = useValidateEquipmentNumbersMutation();
  useEffect(() => {
    setIsSuccessSubmit(isSuccessValidate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessValidate]);

  useEffect(() => {
    setIsErrorSubmit(validateError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateError]);

  const onChangeNumberInput = (event) => {
    setValue(formFields.equipmentsCount, event.target.value);
    var startNumber = getValues(formFields.startNumber);
    var equipmentsCount = getValues(formFields.equipmentsCount);
    console.log("fd", startNumber, equipmentsCount);
    if (startNumber != "" && equipmentsCount != null) {
      var count =
        !Number(equipmentsCount) || Number(equipmentsCount) === undefined
          ? 1
          : Number(equipmentsCount);
      if (count <= 0) count = 1;
      if (count > 999) count = 999;
      setValue(formFields.equipmentsCount, count);

      validateEquipmentNumbers({
        number: startNumber,
        EquipmentsCount: count,
        ProtectionObjectId: objectId,
      }).then((data) => {
        getLastNumber({ startNumber: startNumber, count: count }).then(
          (data) => {
            setValue(formFields.endNumber, data.data?.lastNumber);
          }
        );
      });
    }
  };

  return (
    <Box sx={{ display: "flex", marginBottom: "10px" }}>
      <InputText
        //  onKeyPress={handleClick}
        {...props}
        onChange={onChangeNumberInput}
        // value={searchValue}
        subLabel={"Введите число от 1 до 999"}
        sx={{ width: "350px", borderColor: "red" }}
      />
    </Box>
  );
};

export const OneEquipNumberInput = ({ objectId, ...props }) => {
  //console.log("errors", props)
  let errorType = "";
  if (props.errorTyp?.root) {
    if (props.errorTyp?.root.serverError.type === 461) {
      errorType = props.errorTyp?.root.serverError.message;
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <InputText
        {...props}
        // subLabel={props.errorTyp?.root.serverError.type === 461 && props.errorTyp?.root.serverError.message}
        sx={{ width: "350px", borderColor: "red" }}
      />
      <Typography
        sx={{ color: "red", alignSelf: "center", marginLeft: "20px" }}
      >
        {errorType}
      </Typography>
    </Box>
  );
};
export const EquipmentTypeIdSelectGrouping = ({
  itemoption,
  objectId,
  bgcolor,
  setValue,

  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const [getEquipmentKindSelectElements] = useGetEquipmentKindSelectElementsQuery();

  useEffect(() => {
    objectId &&
    getEquipmentKindSelectElements({
        searchValue,
        objectId,
        equipmentKindId:
          props?.selectedKind && props?.selectedKind != null
            ? props?.selectedKind
            : "",
      })
        .then((res) => {
          setEquipmentSelectElements(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [
    searchValue,
    objectId,
    getEquipmentKindSelectElements,
    equipmentSelectElements,
  ]);

  const options = useMemo(() => {
    //console.log("facFireCompSelect", getEquipmentTypeIdOptionsGroup(equipmentSelectElements)[0]?.items[0])
    return itemoption
      ? [
          itemoption,
          ...getEquipmentTypeIdOptionsGroupAddEquip(equipmentSelectElements),
        ]
      : getEquipmentTypeIdOptionsGroupAddEquip(equipmentSelectElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSelectElements, itemoption, setEquipmentSelectElements]);

  if (
    props?.selectedKind &&
    props?.selectedSystem &&
    options.length > 0 &&
    props?.selectedSystem != null &&
    props?.selectedKind != null
  ) {
    props?.setequipTypesitems(options);
    //props = {...props,defaultValue: {parentId: props?.selectedSystem,name:`${foundObject.name},${foundValue?.name}`,id:props?.selectedKind}}
    //setValue(formFields.equipmentTypeId,JSON.stringify({parentId: props?.selectedSystem,name:`${foundObject.name},${foundValue?.name}`,id:props?.selectedKind}))
  }

  return (
    <SelectGrouping
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      renderValue={(selected) => {
        //console.log("selectedEquip",JSON.parse(selected)?.id)
        return selected && JSON.parse(selected)?.id != null
          ? `${JSON.parse(selected)?.name}`
          : null;
      }}
      // defaultValue={getEquipmentTypeIdOptionsGroup(equipmentSelectElements)[0]?.items[0]}
      bgcolor={bgcolor}
    />
  );
};

export const PerformerSelectGrouping = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: responsibleOrganizationsSelect = [] } =
    useGetResponsibleOrganizationsQuery(searchValue);

  return (
    <Box className="medium_container">
      <SelectGrouping
        {...props}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        options={responsibleOrganizationsSelect}
      />
    </Box>
  );
};

export const ExecutiveUserNameSelectGrouping = (props) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <Box sx={{ maxWidth: "49%" }}>
      <SelectGrouping
        {...props}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        options={[]}
      />
    </Box>
  );
};

export const ExecutiveUserNameSelectSimple = (props) => {
  const { responsibleOrganizationId } = props?.data || {};
  const { data: executives } = useGetExecutiveSelectElementsQuery(
    responsibleOrganizationId,
    { skip: !responsibleOrganizationId }
  );

  const options = useMemo(() => {
    return executives
      ? executives.map(({ id, name }) => ({ value: id, name }))
      : [];
  }, [executives]);

  return (
    <Box sx={{ maxWidth: "49%" }}>
      <SelectSimple {...props} options={options} />
    </Box>
  );
};

export const PrevWorkContractSelect = (props) => {
  const { value } = props;
  const [open, setOpen] = useState(false);
  const handleOpenState = () => setOpen(!open);

  const { data: options, isLoading: isLoadingOptions } =
    useGetContractFilesSelectQuery();

  const prettifyOptions = useCallback((options) => {
    if (!options?.length) {
      return [];
    }
    return options.map((option) => {
      const { contractDate, contractNumber, fileLink } = option;
      const prettifiedContractDateName = contractDate
        ? dayjs(contractDate).format(dateFormat)
        : null;
      const prettifiedContractDateValue = contractDate
        ? dayjs(contractDate).format(dateFormatForSubmit)
        : null;
      return {
        name: `${prettifiedContractDateName} ${contractNumber}`,
        value: JSON.stringify({
          ...option,
          contractDate: prettifiedContractDateValue,
        }),
        fileLink,
      };
    });
  }, []);

  const selectedOption = useMemo(() => {
    if (!options?.length || !value) {
      return null;
    }
    const {
      contractDate,
      contractNumber = 0,
      fileLink = "",
    } = options.find(({ id }) => id === JSON.parse(value)?.id);

    return {
      contractDate: contractDate
        ? dayjs(contractDate).format(dateFormat)
        : null,
      contractNumber,
      fileLink,
    };
  }, [value, options]);

  return (
    !isLoadingOptions && (
      <Box
        sx={{ display: "flex", columnGap: "20px", alignItems: "center" }}
        className="medium_container column_sm"
      >
        <SelectSimple {...props} options={prettifyOptions(options)} />
        {selectedOption ? (
          <Box
            sx={{ display: "flex", columnGap: "10px", alignItems: "center" }}
          >
            {open && (
              <WorkContractFileView
                open={open}
                title={`${selectedOption.contractDate} ${selectedOption.contractNumber}`}
                onClose={handleOpenState}
                img={selectedOption.fileLink}
              />
            )}
            <IconButton onClick={handleOpenState}>
              <PdfIcon />
            </IconButton>
            <Box className="input-wrapper">
              <Typography
                sx={{ mb: 0, color: "var(--main)", whiteSpace: "nowrap" }}
                className="input-block-title"
              >
                {selectedOption.contractNumber}
              </Typography>
              <Typography
                sx={{
                  mt: 0,
                  minHeight: "inherit!important",
                  whiteSpace: "nowrap",
                }}
                className="under-input"
              >
                {selectedOption.contractDate}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    )
  );
};

export const EquipmentKindSelectGrouping = ({
  itemoption,
  bgcolor,
  useShortTitle,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetEquipmentKindSelectElementsQuery({
    params: searchValue,
    useShortTitle: useShortTitle,
  });

  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch]);

  const options = useMemo(() => {
    return data
      ? itemoption
        ? [itemoption, ...getEquipmentTypeIdOptionsGroup(data)]
        : getEquipmentTypeIdOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemoption]);

  return (
    <SelectGroupingSystemPPZ
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      isSystemPPZ={true}
      defaultValue={0}
      bgcolor={bgcolor}
    />
  );
};

export const InputAutocompleteNameEquipments = ({
  label,
  disabled = false,
  onChange,
  error,
  setValue,
  getValues,
  resetField,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = props;
  const { equipmentTypeId } = useWatch({
    control,
  });
  const [searchValue, setSearchValue] = useState("");
  const [optionsName, setoptionsName] = useState([]);
  const [fileLink, setFileLink] = useState("");
  // console.log("equipmentTypeId",props.control)
  //const equipmentTypeId = equipmentTypeId || "";

  let propsAutoComple = {};

  const equipmentTypeIdValue = equipmentTypeId
    ? JSON.parse(equipmentTypeId)?.id
    : "";
  const { data: options = [] } = useGetEquipmentNamesQuery({
    searchValue: searchValue,
    equipmentTypeId: equipmentTypeIdValue,
  });

  // //const [GetEquipmentNames] = useLazyGetEquipmentNamesQuery()
  /* const handleChangeSelect = (selectValue) => {
     if (selectValue) {
       const { typeId: id, typeName: name } = selectValue || {};
       setValue(formFields.equipmentTypeId, JSON.stringify({ id, name }));
     }
   };
 */
  /* useEffect(()=>{
    if(props?.Equipmentype){
      GetEquipmentNames({ searchValue: searchValue, equipmentTypeId: JSON.parse(props?.Equipmentype)?.parentId }).then(({data})=>{
          console.log("ddd",data)
          setoptions(data)
          props?.setequipTypesnames(data)
      })
     
    }
  },[props?.Equipmentype])
  */
  useEffect(() => {
    if (options && options.length > 0) {
      console.log("options", options);
      if (props?.selectedGroup && props?.selectedGroup != null) {
        setoptionsName([
          ...options,
          { id: props?.selectedGroup, equipmentName: props?.selectedGroup },
        ]);
        // props?.setequipTypesnames([...options,{id:props?.selectedGroup,equipmentName:props?.selectedGroup}])
      } else {
        setoptionsName(options);
      }
    } else {
      setoptionsName([
        { id: props?.selectedGroup, equipmentName: props?.selectedGroup },
      ]);
    }
  }, [options, searchValue]);
  const handleInputChange = (newInputValue) => {
    const { fileLink } =
      options.find(({ equipmentName }) => equipmentName === newInputValue) ||
      {};

    setFileLink(fileLink || "");
    onChange(newInputValue);
  };

  if (props?.selectedGroup && props?.selectedGroup != null)
    propsAutoComple = {
      defaultValue: {
        id: props?.selectedGroup,
        equipmentName: props?.selectedGroup,
      },
    };
  /*useEffect(() => {
    if (!value) {
      setSearchValue("");
      resetField(formFields.equipmentTypeId);
    };
    value.length >= 3 && setSearchValue(value);
  }, [value, resetField]);
*/
  return (
    <Box sx={{ position: "relative" }} className="input_text">
      <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      <Stack spacing={2} className="block_with_img">
        <Autocomplete
          freeSolo
          getOptionLabel={(option) => option?.equipmentName}
          options={optionsName}
          onInputChange={(event, newInputValue) =>
            handleInputChange(newInputValue.split("#").shift().trim())
          }
          // onChange={(event, newValue) => handleChangeSelect(newValue)}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.equipmentName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              // label={t(label)}
              error={!!error}
              value={props?.selectedGroup}
            />
          )}
          className="block_with_img-input"
          {...propsAutoComple}
        />

        {!!fileLink && (
          <Box sx={{ position: "absolute", top: "100%" }}>
            <CardMedia
              className="block_with_img-image"
              component="img"
              sx={{ maxWidth: "100px" }}
              image={fileLink}
              alt="preview image"
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
