import React, { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import { dateFormat, dateFormatForSubmit } from "../../../constants";
import "./styles.css";

const DatePickerInbuild = ({ placeholder, onChange, value, bgcolor, className, handleChangeDeadLineDate }) => {
  const style = {
    width: "100%",
    backgroundColor: bgcolor ? bgcolor : "inherit",
    height: "24px",
    "& .MuiInputLabel-root": {
      color: "var(--black)",
      fontFamily: "var(--INTER)",
      fontSize: "var(--standart)",
      fontWeight: "500",
      top: className ? "-11px" : "-16px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "& .MuiOutlinedInput": className
        ? className
        : {
            color: "var(--black)",
            fontFamily: "var(--INTER)",
            fontSize: "var(--standart)",
            fontWeight: "500",
            paddingTop: "0",
            paddingBottom: "0",
          },
    },
    "& fieldset": { padding: "0" },
    "& .MuiFormLabel-filled.MuiInputLabel-shrink": {
      display: "none",
      position: "absolute",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& .MuiOutlinedInput-input": { padding: "0", width: "100%" },
    "& .MuiInputBase-root": {
      paddingRight: "0",
      height: className ? "24px" : "inherit",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiButtonBase-root": {
      padding: "0",
      marginRight: "2px",
      "& svg": {
        width: "20px",
      },
    },
  };

  const handleChange = (e) => {
    if (!e) {
      onChange(null);
      return;
    }
    const date = e.$d;
    const newValue = isNaN(Date.parse(date)) ? "" : dayjs(date).format(dateFormatForSubmit);
    onChange(newValue);
  };

  const datePickerRef = useRef(null);

  useEffect(() => {
    const inputElement = datePickerRef.current?.querySelector('input');
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleChangeDeadLineDate(event.target.value)
      }
    };

    inputElement.addEventListener("keypress", handleKeyPress);

    return () => {
      inputElement.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  function formatDate() {
    if(value.includes("-")) return dayjs(value)
    const preparedData = value.split(".");
    const date = new Date(+preparedData[2], +preparedData[1] - 1, +preparedData[0]);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return dayjs(`${year}-${month}-${day}`);
}

  function onChangeData(e) {
    console.log(e)
  }

  const slots = value
    ? {
        inputAdornment: () => (
          <IconButton
            type="button"
            aria-label="clean search"
            onClick={() => {
              onChange("");
            }}
            sx={{ padding: "0" }}
          >
            <ClearIcon sx={{ fontSize: 22 }} />
          </IconButton>
        ),
      }
    : {};

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ru"
      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <DatePicker
        id={"date-picker"}
        ref={datePickerRef}
        className={`date-picker ${className}`}
        sx={style}
        label={placeholder}
        format={dateFormat}
        fullWidth
        value={value ? formatDate() : null}
        onAccept={handleChange}
        slots={slots}
        onChange={(e) => onChangeData(e)}
        // onKeyPress={handleKeyPress}
      />
    </LocalizationProvider>
  );
};

export default DatePickerInbuild;
